import { Web3 } from 'web3';
//import Eth from 'web3-eth';
import * as Config from './udice.config.ts';
import {UDICESession} from './udice-connect-session.ts';
import { EIP6963AnnounceProviderEvent, EIP6963ProviderDetail, EIP6963ProviderInfo, onNewProviderDiscovered, requestEIP6963Providers } from './../../node_modules/web3/src/web3_eip6963.ts';
import { Web3APISpec, EIP1193Provider, ProviderRpcError } from "web3-types";

export class UDICEProvider{
    static listUDICEProviders = listUDICEProviders;
    static newProviderDiscovered = newProviderDiscovered;

    private _provider: EIP1193Provider<unknown>;
    private _web3: Web3;
    public readonly name: string;
    public readonly icon: string;

    constructor(provider: EIP1193Provider<unknown>, name:string, icon:string){
        this._provider = provider;
        this._web3 = new Web3(this._provider);
        this.name = name;
        this.icon = icon;
        
        //this.provider.on("chainChanged", (chainId)=>{
          //  alert("chainChanged: "+chainId);
        //});
        //this.provider.on("chainChanged", (chainId)=>{
        //    alert("chainChanged: "+chainId);
        //});
        //this.provider.on('disconnect', (code, reason) => {
          //  console.log(`Ethereum Provider connection closed: ${reason}. Code: ${code}`);
          //});
          this.provider.on('disconnect', (error: ProviderRpcError)=>{
            console.log(`Ethereum Provider connection closed: ${error.cause}. Code: ${error.code}. Message: ${error.message}`);
        });
        //this.provider.on('accountsChanged', (accounts)=>{
        //    console.log(accounts);
        //});
    }
    get web3(){ return this._web3};
    get provider(){return this._provider};

    async getChainId(){
        const chainId = await this._web3.eth.getChainId();
        return chainId;
    }

    addChainChangedEventListener(listener){
        let chainIdChangeListener = function (chainId){
            let chainIdInt = BigInt(chainId).toString(10);
            listener(chainIdInt);
        };
        this._provider.on("chainChanged", chainIdChangeListener);
        return {"unsubscribe": ()=> this._provider.removeListener("chainChanged", chainIdChangeListener)};
    }

    addAccountsChangedEventListener(listener){
        let accountsChangeListener = function (accounts){
            listener(accounts);
        };
        this._provider.on("accountsChanged", accountsChangeListener);
        return {"unsubscribe": ()=> this._provider.removeListener("accountsChanged", accountsChangeListener)};
    }

    async getAccounts(){
        return await this._web3.eth.requestAccounts();
    }

}

//https://eips.ethereum.org/EIPS/eip-1193
export class UDICEProviderEIP6963 extends UDICEProvider{
    constructor(eip6963ProviderDetail: EIP6963ProviderDetail<unknown>){
        super(eip6963ProviderDetail.provider, eip6963ProviderDetail.info.name, eip6963ProviderDetail.info.icon);
        
    }

}

    export class UDICEProviderLegacy extends UDICEProvider{
        constructor(eip1193Provider:EIP1193Provider<unknown>){
            super(eip1193Provider, "Default", "");
        }
    }
    async function listUDICEProviders(): Promise<UDICEProvider[]>{
        let result = Promise.withResolvers<UDICEProviderEIP6963[]>();

        if(!Web3.requestEIP6963Providers){
            return fallbackToMetamask(result);
        }
        
        loadEIP6963Providers(result);

        return result.promise;
    }

    async function newProviderDiscovered(handleProvider: (key:string, provider: UDICEProviderEIP6963)=>void){
        var unsubscribed: boolean;
        Web3.onNewProviderDiscovered((event: EIP6963AnnounceProviderEvent<unknown>) => {
            if(unsubscribed) return;
            let detail: any = event.detail;
            for(const [key, eip6963ProviderDetail] of detail){
                handleProvider(key, new UDICEProviderEIP6963(eip6963ProviderDetail));
            }
          });
          return {'unsubscribe': ()=>{
            unsubscribed = true;
        }};
    }

    async function loadEIP6963Providers(result : PromiseWithResolvers<UDICEProviderEIP6963[]>): Promise<UDICEProvider[]>{
        {
            //work around for case when no provider was found
            setTimeout(()=>{
                fallbackToMetamask(result);
            }, 500);
        }

        setTimeout(async ()=>{
            var eip6963Providers: Map<string, EIP6963ProviderDetail<unknown>> = await Web3.requestEIP6963Providers() as Map<string, EIP6963ProviderDetail<unknown>>;

            let resultProviders:UDICEProviderEIP6963[] = Array.from(eip6963Providers, ([k, v])=>v)
                .map(eip6963Provider=>new UDICEProviderEIP6963(eip6963Provider));
    
            if(resultProviders.length==0){
                return fallbackToMetamask(result);
            }
    
            result.resolve(resultProviders);
        }, 10);
        return result.promise;
    }

    async function fallbackToMetamask(result : PromiseWithResolvers<UDICEProviderEIP6963[]>): Promise<UDICEProvider[]>{
        if(window['ethereum']!=null){
            result.resolve([new UDICEProviderLegacy(window['ethereum'])]);
        }else{
            console.log('MetaMask account not detected :(');
            result.resolve([]);
        }
        return result.promise;
    }