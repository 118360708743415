import Analytics, { AnalyticsInstance } from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

/*
export const analytics = Analytics({
    app: 'awesome-app',
    plugins: [
      googleAnalytics({
        measurementIds: ['G-abc123']
      })
    ]
  })
    */
/*
async function trackPageView(){
  // Track a page view
  analytics.page();
}

  (async function(){
    analytics.page();
    })();
  */
var _analytics: AnalyticsInstance;

export async function analytics():Promise<AnalyticsInstance>{
  if(_analytics!=null) return _analytics;

  if(document.readyState!="complete"){
    let result = Promise.withResolvers<AnalyticsInstance>();
    window.addEventListener("load", e=>{
        result.resolve(analytics());
    });
    return result.promise;
  }

  _analytics = Analytics({
    app: 'awesome-app',
    plugins: [
      googleAnalytics({
        measurementIds: ['G-FFLR89ZDGW']
      })
    ], debug: isDebugModeEnabled()
  });
  return _analytics;
}

analytics().then(a=>a.page());

function isDebugModeEnabled(): boolean {
  return window.localStorage.getItem("udice-analytics-debug")!=null;
}

if(window.location.search.indexOf("debug=true")>=0){
  window.localStorage.setItem("udice-analytics-debug", "1");
}else if(window.location.search.indexOf("debug=false")){
  window.localStorage.removeItem("udice-analytics-debug");
}
