import {analytics} from "./udice-app-analytics.ts";
import {default as Collapse} from "./../../node_modules/bootstrap/js/src/collapse.js";

var instances: IUDICENav[] = [];

export interface IUDICENav{
    addNavLink(id: string, title:string, active:boolean, section: HTMLElement): HTMLLIElement;
    addNavLinkAction(id: string, title:string, listener: ()=>void): HTMLLIElement;
}

export function getNavs(): IUDICENav[]{
    return instances;
}

export class UDICENav extends HTMLElement implements IUDICENav{

    _navListElement: HTMLUListElement;
    _collapse: Collapse;

    constructor(){
        super();

        this.classList.add("navbar", "navbar-expand-lg", "navbar-dark", "bg-dark");

        this.innerHTML = `
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img src="/img/logo.png" alt="UDUH" width="30" height="24"/>
                UDUH
                <span class="badge bg-danger" style="font-size: xx-small;vertical-align: sub;">beta</span>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent1">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <!--
                    <li class="nav-item">
                        <a id="navbar-link-play" class="nav-link active nav-page" data-page="page-play" aria-current="page" href="#page-play">Play</a>
                    </li>
                    <li class="nav-item">
                        <a id="navbar-link-info" class="nav-link nav-page" data-page="page-info" href="#page-info">Info</a>
                    </li>
                    -->
                    <li class="nav-item">
                        <a id="navbar-link-docs" class="nav-link" data-page="page-docs" aria-current="page" href="/docs/"><i class="bi bi-newspaper"></i> Lightpaper</a>
                    </li>
                </ul>
            </div>
        </div>
 
        `;
        let toggler = this.querySelector(".navbar-toggler");
        let collapseElement = this.querySelector(".collapse");
        //let collapse = new Collapse(collapseElement, {toggle: false});
        //let collapse = Collapse.getOrCreateInstance(collapseElement);
        //this._collapse = new Collapse(collapseElement, {toggle: false});
        this._collapse = Collapse.getOrCreateInstance(collapseElement, {toggle: false});
        //
        //Collapse.getOrCreateInstance(toggler);
        
        toggler.addEventListener("click", e=>{
            this._collapse.toggle();
        });

        this._navListElement = this.querySelector("#navbarSupportedContent1 ul");
    }

    addNavLink(id: string, title:string, active:boolean, section: HTMLElement){
        let a: HTMLAnchorElement  = document.createElement("a");
        a.id = id;
        a.innerHTML = title;
        a.classList.add("nav-link", "nav-page");
        a['_section'] = section;
        a.href = "#"+id;

        let li: HTMLLIElement = document.createElement("li");
        li.classList.add("nav-item");
        li.appendChild(a);

        a.addEventListener("click", e=>{
            e.preventDefault();
            this.activateElement(a);
            analytics().then(a=>a.track(id));
        });

        this._navListElement.appendChild(li);

        //Collapse.getOrCreateInstance();
        if(active){
            this.activateElement(a);
        }
        section.querySelectorAll(".nav-page").forEach((pageLink: HTMLAnchorElement)=>{
            pageLink.addEventListener("click", e=>{
                e.preventDefault();
                let a: HTMLAnchorElement = this._navListElement.querySelector("a[href='"+pageLink.hash+"']")
                this.activateElement(a);
            })
        })
        return li;
    }

    addNavLinkAction(id: string, title:string, listener: ()=>void){
        let a: HTMLAnchorElement  = document.createElement("a");
        a.id = id;
        a.innerHTML = title;
        a.classList.add("nav-link", "nav-page");
        //a['_section'] = section;
        a.href = "#"+id;

        let li: HTMLLIElement = document.createElement("li");
        li.classList.add("nav-item");
        li.appendChild(a);

        a.addEventListener("click", e=>{
            e.preventDefault();
            analytics().then(a=>a.track(id));
            listener();
        });

        this._navListElement.appendChild(li);
        return li;
    }

    activateElement(targetPageLink: HTMLAnchorElement){
        this.querySelectorAll(".nav-page").forEach(otherPageLink=>{
            if(otherPageLink!=targetPageLink){
                otherPageLink.classList.remove("active");
                if(otherPageLink['_section']){
                    otherPageLink['_section'].classList.add("d-none");
                }
            } 
            //otherPageLink['_section'].classList.add('d-none');
            //Collapse.getOrCreateInstance(otherPageLink).hide();
        });
        targetPageLink.classList.add("active");

        targetPageLink['_section'].classList.remove("d-none");
        //Collapse.getOrCreateInstance(targetPageLink).show();
        this._collapse.hide();
    }

    connectedCallback(){
        instances.push(this);
    }
    disconnectedCallback(){
        for(var i=0; i<instances.length; i++){
            if(instances[i]==this){
                for(var j=i+1; j<instances.length; j++){
                    instances[j-1] = instances[j];
                }
                instances.pop();
            }
        }
    }
}
customElements.define("udice-application-navbar", UDICENav)

export class UDICEPageFooter extends HTMLElement{

    constructor(){
        super();
        let copyrightYears = "2024" + ( new Date().getUTCFullYear()>2024?" "+ new Date().getUTCFullYear():"");
        this.innerHTML = `
            <hr class="mt-5" />
            <div class="d-flex justify-content-center p-4">
                    <a href="https://x.com/uduh_app" target="_blank" class="btn btn-lg0 btn-dark"><i class="bi bi-twitter-x"></i> </a>
                    <a href="https://t.me/uduh_app" target="_blank" class="btn btn-lg0 btn-dark"> <i class="bi bi-telegram"></i> </a>
                    <a href="https://github.com/uduh-app/" target="_blank" class="btn btn-lg0 btn-dark"><i class="bi bi-github"></i> </a>
                    <br />
                    <a href="/docs/" target="_blank" class="btn btn-dark"><i class="bi bi-newspaper"></i> lightpaper</a>
            </div>
            <div class="d-flex justify-content-center">
                &copy ${copyrightYears}, All rights reserved
            </div>
        `;
    }
}
customElements.define("udice-application-pagefooter", UDICEPageFooter)