import { Web3, Web3EthInterface } from 'web3';
import { IUdiceContractSupport, IUdiceValuer, UdiceValuer } from './udice.config.ts';

// PancakeSwap Router and Factory contract addresses
const PANCAKESWAP_ROUTER_ADDRESS = '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F'; // Example Router Address
//const PANCAKESWAP_FACTORY_ADDRESS = '0x05fF3c7e38C1d021ecAC9a1F768F0c2E67eC6EB7'; // Example Factory Address

// Token and USDT contract addresses
const TOKEN_ADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'; // Replace with your token address
const USDT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955'; // USDT Address on BSC

// PancakeSwap Router ABI
const routerAbi = [
    // Only including the relevant part of the ABI for simplicity
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amountIn",
                "type": "uint256"
            },
            {
                "internalType": "address[]",
                "name": "path",
                "type": "address[]"
            }
        ],
        "name": "getAmountsOut",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "amounts",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
  ];

async function getBNBPrice(account: string, web3: Web3){
    const routerContract = new web3.eth.Contract(routerAbi, "0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F");

    try {
      let amountIn = web3.utils.toWei('1', 'ether');
      let path = ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', '0x55d398326f99059fF775485246999027B3197955']; 
      const amountsOut = await routerContract.methods.getAmountsOut(BigInt(amountIn), path).call();
      const priceInUSDT = web3.utils.fromWei(amountsOut[1], 'ether');
      console.log(`Price of 1 ${TOKEN_ADDRESS} in USDT: ${priceInUSDT}`);
      return amountsOut[1];
    } catch (error) {
        console.error(JSON.stringify(error));
      console.error('Error getting price:', error);
      return Promise.reject(error);
    }
}
//getPrice();
class IUdiceContractSupportImp implements IUdiceContractSupport{
    
    private web3: Web3;
    private routerContract: any;
    private cachedCoinPriceUSDT: bigint;
    private cachedCountTs: number;
    
    constructor(web3: Web3){
        this.web3 = web3;
        this.routerContract = new web3.eth.Contract(routerAbi, "0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F");
    }

    async getValuer(): Promise<IUdiceValuer> {
        let perCoin = await this.getCoinPriceUSDT();
        let perToken = null;
        return new UdiceValuer(this.web3, perCoin, perToken, "USDT");
    }

    async getCoinPriceUSDT(): Promise<bigint> {
        if(this.cachedCoinPriceUSDT!=null && this.cachedCountTs!=null && this.cachedCountTs > Date.now()-1000*60){
            return this.cachedCoinPriceUSDT;
        }
        try {
            let amountIn = this.web3.utils.toWei('1', 'ether');
            let path = ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', '0x55d398326f99059fF775485246999027B3197955']; 
            const amountsOut = await this.routerContract.methods.getAmountsOut(BigInt(amountIn), path).call();
            const priceInUSDT = this.web3.utils.fromWei(amountsOut[1], 'ether');
            console.log(`Price of 1 ${TOKEN_ADDRESS} in USDT: ${priceInUSDT}`);

            this.cachedCoinPriceUSDT = amountsOut[1];
            this.cachedCountTs = Date.now();
            return amountsOut[1];
          } catch (error) {
            console.error(JSON.stringify(error));
            console.error('Error getting price:', error);
            return Promise.reject(error);
          }
    }

}
export function createIUdiceContractSupport(web3: Web3){
    return new IUdiceContractSupportImp(web3);
}