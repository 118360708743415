

export class CountDown extends HTMLElement{

    public _target: Date;
    private _timerId: any;
    private _days: HTMLElement;
    private _hours: HTMLElement;
    private _minutes: HTMLElement;
    private _seconds: HTMLElement;

    constructor(){
        super();

        this.innerHTML = `
            <span>
                <span class="days"></span> : 
                <span class="hours"></span> : 
                <span class="minutes"></span> : 
                <span class="seconds"></span>
            </span>
        `;
        this._days = this.querySelector(".days");
        this._hours = this.querySelector(".hours");
        this._minutes = this.querySelector(".minutes");
        this._seconds = this.querySelector(".seconds");
    }

    get date(): Date{
        return this._target;
    }
    set date(value: Date){
        this._target = value;

        this.updateTimerUi();
        this.tearDownTime();
        this.setupTime();
    }

    tearDownTime() {
        if(this._timerId){
            clearInterval(this._timerId);
        }
        this._timerId = undefined;
    }
    setupTime() {
        this.tearDownTime();
        this._timerId = setInterval(()=>this.updateTimerUi(), 1000);
    }

    updateTimerUi(){
        // Get the current date and time
        let now = new Date().getTime();
        
        // Calculate the distance between now and the countdown date
        var distance = this._target.getTime() - now;
        if(distance < 0){
            distance = 0;
        }

        // Calculate days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this._days.innerHTML = days.toString().padStart(2, '0');
        this._hours.innerHTML = hours.toString().padStart(2, '0');
        this._minutes.innerHTML = minutes.toString().padStart(2, '0');
        this._seconds.innerHTML = seconds.toString().padStart(2, '0');

        if(distance==0){
            this.tearDownTime();
        }
        this.title = this._target.toLocaleString();
    }

}

customElements.define("udice-countdown", CountDown);

new CountDown();