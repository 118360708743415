import "./udice-app-utils.ts"
import { UDICEDialog, UDICEUtils } from "./udice-app-utils.ts";

export class UDICERulesParagraph extends HTMLElement{

    constructor(){
        super();
        this.innerHTML = `
                The ultimate decentralized polyhedral-dice game, where players wager against transparent super-customizable odds.
                Each roll offers the chance for up to 60,000X rewards, UDUH token earnings, and eventual control of the house.
        `;
    }
}
customElements.define('udice-application-rules-paragraph', UDICERulesParagraph);

new UDICERulesParagraph();

export class UDICERules extends HTMLElement{
    constructor(){
        super();
        this.innerHTML = `
                    <ol>
                        <li>You submit a wager (W) and a multiplier factor (M)</li>
                        <li>If you win, you get back the wager multiplied by your chosen factor (W ✕ M)</li>
                        <li>If you lose, you get UDUH tokens in proportional to the wager</li>
                        <li>
                            Win or lose, same amout of UDUH tokens will be issued. For a losing bet, UDUH tokens go to a losing player. For a winning bet, UDUH tokens go to the team.
                            <!--The team really wants you to win, because that is the only way the team win some UDUH tokens.-->
                        </li>
                        <li>If you win and there is no enough coins in jackpot for your payout, you get your wager back and a generous dose of UDUH tokens in proportional to the remaining amount (payout - wager)</li>
                        <li>
                            No tokens pre-mining, no team allocation, no dancing unlocks.
                            Every UDUH token is issued as a result of player betting action. Fair launch, fair UDUH token issuance. 
                        </li>
                        <li>
                            UDUH token issuance will be decreasing as the game progresses to control inflation and eventually limit supply.
                            Eventually, no UDUH tokens will be issued and the only way to obtain UDUH tokens will be by purchasing them from the market.
                            After UDUH token issuance ceases, players will be playing solely for the chance to multiply their wagers, and lost bets will have no rewards.
                        </li>
                        <li>
                            What you will be able to do with your UDUH tokens
                            <ul>
                                <li>Burn them in exchange for multiplier above 1000</li>
                                <li>Burn them in exchange for a proportional share of the jackpot. In beginning there will be a high tax on withdrawals to protect the jackpot in its nascent stage. The tax will gradually decrease as the game is played and will eventually reach 0 to allow free buring of UDUH tokens in exchange for a proportinal amount of the jackpot</li>
                                <li>Hodl them as a badge of honour for your contribution to the jackpot of the ultimate dice game of all time</li>
                                <li>Trade them in exchanges</li>
                                <li>Vote and help govern the future of the game</li>
                            </ul>
                        </li>
                        <li>
                            For every bet, a small fixed project fee is charged to cover project costs, but you have a choice.
                            You may choose to pay it upfront, or you may let the house pay it for you in exchange for a slight increase in house edge.
                        </li>
                    </ol>   
        `;   
    }
}
customElements.define('udice-application-rules-summary', UDICERules);

export class UDICERulesPoetry extends HTMLElement{
    constructor(){
        super();
        this.innerHTML = `

                    <p>
                    Welcome to the ultimate decentralized dice game, where every roll promises exploding wins, house ownership, or both! Are you ready to test your luck against the decentralized odds?
                    </p>
                    <p>
                    In this game, players embark on an exciting journey of wagering and chance, all within the realm of fair and transparent gameplay. Here's how it works:
                    </p>
                    <p>
                    You begin by submitting your wager and selecting a multiplying factor, with options ranging from cautious to adventurous. The bet number you choose determines your odds of winning, with higher risks potentially leading to higher rewards.
                    </p>
                    <p>
                    Once your wager is placed, the smart contract springs into action, generating a roll of the dice with absolute fairness. If the roll number falls below your chosen bet number, congratulations! You've secured a win and are rewarded handsomely with your wager multiplied by your chosen factor.
                    </p>
                    <p>
                    But fret not, should luck evade you on a particular roll, you won't walk away empty-handed. You get UDUH (/juː duː/) tokens in proportion to your wager, ensuring that every lost throw of the dice contributes to your ownership of the house.
                    </p>
                    <p>
                    What's more, the innovative jackpot system ensures that every bet fuels the growth of the grand prize, offering tantalizing opportunities for big prize wins.
                    </p>
                    <p>
                    So, are you ready to yoo-doo!? Just remember, if you dice it, prepare to either win, own the house, or both. Join us now and let the dice roll in your favor! Welcome to decentralized dice revolution!
                    </p>
   
        `;   
    }
}
customElements.define('udice-application-rules-poetry', UDICERulesPoetry);

new UDICERulesPoetry();

export class UDICERulesCombined extends HTMLElement{

    constructor(){
        super();
        //UDICEUtils.installBootstrap();
        this.innerHTML = `
            <!--
            <h2 class="border-bottom mb-2 pb-2 mt-3">TL;DR</h2>
            <udice-application-rules-paragraph></udice-application-rules-paragraph>
            -->
            <h2 class="border-bottom mb-2 pb-2 mt-4">To the point</h2>
            <udice-application-rules-summary></udice-application-rules-summary>
            
            <!--
            <h2 class="border-bottom mb-2 pb-2 mt-4">Poetry</h2>
            <udice-application-rules-poetry></udice-application-rules-poetry>
            -->

            <a href="/docs/" class="btn btn-primary">Lightpaper</a>

        `;
    }
}

customElements.define('udice-application-rules', UDICERulesCombined);

new UDICERulesCombined();

export function showRulesPage(){
    let dialog:UDICEDialog = new UDICEDialog();

    const rulesPage = new UDICERulesCombined();
    rulesPage.classList.add("container");

    dialog.bodyContent = rulesPage;
    dialog.fullscreen = true;
    dialog.titleContent = '<i class="bi bi-file-earmark-text"></i> House Rules';
    dialog.show();
}

export class UDICERulesButton extends HTMLElement{
    static dummy(){}
    constructor(){
        super();
        this.addEventListener("click", e=>showRulesPage())
    }
}
customElements.define("udice-application-rules-button", UDICERulesButton)
UDICERulesButton.dummy();
