import { UDICEDialog, UDICEUtils } from './udice-app-utils.ts';
import { ISubmission } from './udice-connect-contract.ts';
import * as UDICEProviders from './udice-connect-providers.js';
import * as UDICESessions from "./udice-connect-session.ts";
import * as UDICEConfig from './udice.config.ts';

/*
class ItemsManager{
    private _session: UDICESessions.UDICESession;
    private _container: HTMLElement;
    private _items: Map<bigint, UDICESubmissionsListItem> = new Map();

    constructor(session: UDICESessions.UDICESession, container: HTMLElement){
        this._session = session;
        this._container = container;
    }

    set session(value: UDICESessions.UDICESession){
        this._session = value;
        this._container.innerHTML = '';
        this._items.clear();
    }

    findInsetionPoint(submission:  UDICESessions.UDICESubmission){
        for(var i=0; i<this._container.children.length; i++){
            let s: UDICESessions.UDICESubmission = this._container.children[i]['submission'];
            if(s.submissionId < submission.submissionId){
                return this._container.children[i];
            }
        }
        return null;
    }
    add(submission:  UDICESessions.UDICESubmission){
        var item = this._items.get(submission.submissionId);
        if(item==null){
            item = new UDICESubmissionsListItem(this._session);
            //this._container.insertBefore(item, this._container.firstElementChild);
            this._container.insertBefore(item, this.findInsetionPoint(submission));
            this._items.set(submission.submissionId, item);
        }
        item.submission = submission;
    }
}
*/

export class UDICESubmissionsList extends HTMLElement{
    static dummy(){}

    private _allItems: UDICESubmissionsBrowser;
    private _myItems: UDICEMySubmissionsBrowser;

    constructor(){
        super();
        this.innerHTML = `
            <h2 class='mt-3 border-bottom border-secondary'>Bets</h2>
            <ul class="nav nav-tabs">
                <li class="nav-item nav-item-all">
                    <a class="nav-link active" aria-current="page" href="#">All Bets</a>
                </li>
                <li class="nav-item nav-item-my">
                    <a class="nav-link" href="#">My Bets</a>
                </li>
            </ul>
        `;
        this._allItems = new UDICESubmissionsBrowser();
        this._myItems = new UDICEMySubmissionsBrowser();
        this._myItems.classList.add("d-none");
        this.appendChild(this._allItems);
        this.appendChild(this._myItems);

        let allNavItem = this.querySelector(".nav-item-all a") as HTMLElement;
        let myNavItem = this.querySelector(".nav-item-my a") as HTMLElement;

        allNavItem.addEventListener("click", e=>{
            e.preventDefault();
            allNavItem.classList.add("active");
            myNavItem.classList.remove("active");
            this._allItems.classList.remove("d-none");
            this._myItems.classList.add("d-none");

        });
        myNavItem.addEventListener("click", e=>{
            e.preventDefault();
            allNavItem.classList.remove("active");
            myNavItem.classList.add("active");
            this._allItems.classList.add("d-none");
            this._myItems.classList.remove("d-none");
        });
    }
    get session(): UDICESessions.UDICESession{
        return this._allItems.session;
    }
    set session(value: UDICESessions.UDICESession){
        this._allItems.session = value;
        this._myItems.session = value;
    }
}
customElements.define('udice-application-submissions-list', UDICESubmissionsList);
UDICESubmissionsList.dummy();

export class UDICESubmissionsListItem extends HTMLElement{
    _submissionIdElement: HTMLElement;
    _submissionTime: HTMLElement;
    _playerIdElement: HTMLElement;
    _wagerElement: HTMLElement;
    _multiplierElement: HTMLElement;
    _betElement: HTMLElement;
    _statusElement: HTMLElement;
    _rollElement : HTMLElement;
    _receivedCoinsAndTokensElement: HTMLElement;
    _session: UDICESessions.UDICESession;
    _submission: any;

    constructor(session : UDICESessions.UDICESession){
        super();
        this.session = session;

        this.classList.add("list-group-item");
        this.innerHTML = `
            <div class="row">
                <div class="col-6 col-md-4 col-lg-2 col-xxl-2">
                    <span class="submission-id"></span>
                    <span class="submission-created"><span>
                </div>
                <div class="col-6 col-md-4 col-lg-2 col-xxl-2">
                    <span class="submission-player-id"></span>
                </div>
                <div class="col-6 col-md-4 col-lg-2 col-xxl-2">
                    <span class="badge bg-dark">
                        <span class="submission-wager"></span>
                        @<span class="submission-multiplier"></span>
                    </span>
                </div>
                <div class="col-6 col-md-4 col-lg-2 col-xxl-2 text-end">
                    <span class="submission-roll"></span>
                    ≤
                    <span class="submission-bet"></span>
                </div>
                <div class="col-6 col-md-4 col-lg-2 col-xxl-2 text-center">
                    <span class="submission-status"></span>
                </div>
                <div class="col-6 col-md-4 col-lg-2 col-xxl-2 text-end">
                    <span class="submission-received"></span>
                </div>
            </div>
        `;
        this._submissionIdElement = this.querySelector(".submission-id");
        this._submissionTime = this.querySelector(".submission-created");
        this._playerIdElement = this.querySelector(".submission-player-id");
        this._wagerElement = this.querySelector(".submission-wager");
        this._multiplierElement = this.querySelector(".submission-multiplier");
        this._betElement = this.querySelector(".submission-bet");
        this._statusElement = this.querySelector(".submission-status");
        this._rollElement = this.querySelector(".submission-roll");
        //this._receivedCoinsElement = this.querySelector(".submission-receivedCoins");
        //this._receivedTokensElement = this.querySelector(".submission-receivedTokens");
        this._receivedCoinsAndTokensElement = this.querySelector(".submission-received");
        //this._submissionIdElement = this.querySelector(".submission-id");
        //this._submissionIdElement = this.querySelector(".submission-id");
        //this._submissionIdElement = this.querySelector(".submission-id");
    }

    get session() : UDICESessions.UDICESession{ return this._session; }
    set session(value : UDICESessions.UDICESession) { this._session = value; }

    get submission() : UDICESessions.UDICESubmission{
        return this._submission;
    }

    set submission(value : UDICESessions.UDICESubmission){
        this._submission = value;
        this.updateUi(value);
        /*
        if(value.status == UDICESessions.SUBMISSION_STATUS_RESOLVING){
            //pending
            this.session.getSubmission(value.submissionId).then(submission=>{
                this.updateUi(submission);
                submission.resolve().then(sub=>{
                    this.updateUi(sub);
                });

            });
        }
        */
        value.addChangeListener(sub=>{
            this.updateUi(sub);
        });
    }

    static makeStatusHTML(status:number): string{
        if(status==UDICESessions.SUBMISSION_STATUS_RESOLVING){
            return "⏳ 🛠️🔄 ";
            return `<i class="bi bi-hourglass-split"></i>`
        }else if(status==UDICESessions.SUBMISSION_STATUS_WON){
            return "🎉🏆";
            return `<i class="bi bi-check-all"></i>`;
        }else if(status==UDICESessions.SUBMISSION_STATUS_LOST){
            return `<i class="bi bi-x-circle"></i>`;
        }/*else if(status==UDICESessions.SUBMISSION_STATUS_DONATED){
            return "🎁🩸";
            return `<i class="bi bi-house-heart-fill"></i>`;
        }*/else if(status==UDICESessions.SUBMISSION_STATUS_REFUNDED){
            return "💸🔄 ";
            return `<i class="bi bi-arrow-left-circle-fill"></i>`;
        }
        return status.toString(10);
    }
    static setRollHTML(submission : UDICESessions.UDICESubmission, _rollElement: HTMLElement){
        if(submission.status!=null && submission.status > UDICESessions.SUBMISSION_STATUS_RESOLVING){
            if(submission.outcome.roll == 0 || submission.outcome.roll == null){
                _rollElement.innerHTML = `<i class="bi bi-send-exclamation"></i>`;
            }else{
                _rollElement.innerHTML = ""+submission.outcome.roll;
            }
        }else{
            _rollElement.innerHTML = `
                <button class='btn btn-primary btn-sm btn-resolving-refresh' title="Refresh"><i class="bi bi-arrow-clockwise"></i> Refresh</button>
                <button class='btn btn-danger btn-sm btn-resolving-refund' title="Refund"><i class="bi bi-arrow-counterclockwise"></i> Refund</button>`;
            let buttonRefresh:HTMLButtonElement = _rollElement.querySelector(".btn-resolving-refresh");
            let buttonRefund:HTMLButtonElement = _rollElement.querySelector(".btn-resolving-refund");
            buttonRefresh.addEventListener("click", e=>{
                buttonRefresh.disabled = true;
                submission.refresh()
                    .finally(()=>{
                        buttonRefresh.disabled = false;
                    });;
            });

            buttonRefund.classList.add("d-none");
            this.refreshWithExponentialBackOff(submission).finally(()=>{
                buttonRefund.classList.remove("d-none");
            });
            setTimeout(()=>buttonRefund.classList.remove("d-none"), 30_000);

            buttonRefund.addEventListener("click", e=>{
                buttonRefund.disabled = true;
                submission.refund()
                    .finally(()=>{
                        buttonRefund.disabled = false;
                    })
            });
        }
    }

    static async refreshWithExponentialBackOff(submission : UDICESessions.UDICESubmission): Promise<ISubmission>{
        let attempt = 0;
        let maxRetries = 5
        let initialDelay = 3000;
        while (attempt < maxRetries) {
            let r = await submission.refresh();
            if(r.status!=UDICESessions.SUBMISSION_STATUS_RESOLVING){
                return r;
            }else{
                const delay = initialDelay * Math.pow(2, attempt++);
                await new Promise(resolve => setTimeout(resolve, delay));
            }
        }
        return submission.refresh();
    }

    static async makePayoutHTML(submission : UDICESessions.UDICESubmission, _session:UDICESessions.UDICESession){
        if(submission.outcome==null){
            return "";
        }
        if(submission.outcome!=null){
            /*
            if(submission.outcome.payoutCoins!=null && submission.outcome.refundCoins!=null){
                this._receivedCoinsElement.innerHTML = this.session.fromWei(submission.outcome.payoutCoins + submission.outcome.refundCoins);
            }
            if(submission.outcome.payoutTokens!=null){
                this._receivedTokensElement.innerHTML = this.session.fromWei(submission.outcome.payoutTokens);
            }
            */

            let x = [];
            let coinSymbol : string = _session.getCoinsSymbol();
            let tokenSymbol : string = await _session.getTokensSymbol();
            if(submission.outcome.payoutCoins!=null && submission.outcome.refundCoins!=null){
                let coins = submission.outcome.payoutCoins + submission.outcome.refundCoins;
                if(coins > 0){
                    x.push(_session.fromWei(coins)+" "+coinSymbol)
                }
            }
            if(submission.outcome.payoutTokens!=null){
                if(submission.outcome.payoutTokens > 0){
                    x.push(_session.fromWei(submission.outcome.payoutTokens)+" "+tokenSymbol)
                }
            }
            if(x.length==0){
                x.push("0 "+coinSymbol);
            }
            return x.join(" + ");
        }
    }

    async updateUi(submission? : UDICESessions.UDICESubmission){
        //let value : UDICESessions.UDICESubmission = await submission;
        this._submission = submission;
        this._submissionIdElement.innerHTML = `<a href="#" class="btn btn-primary">#${submission.submissionId.toString(10)}</a>`;
        this._submissionIdElement.querySelector("a").addEventListener("click", e=>{
            e.preventDefault();
            UDICESubmissionDetails.showDialog(this.session, submission);
        });
        this._playerIdElement.innerHTML = submission.player.substring(0,5)+"..."+submission.player.substring(submission.player.length-3);
        if(this._session.account.localeCompare(submission.player, undefined, { sensitivity: 'accent' })===0){
            this._playerIdElement.innerHTML = "👤 " + this._playerIdElement.innerHTML;
        }
        this._wagerElement.innerHTML = this.session.fromWei(submission.wager) +" "+ this._session.getCoinsSymbol();
        this._multiplierElement .innerHTML = this.session.fromWei(submission.multiplier)+"X";
        if(submission.bet==null){
            this._betElement.innerHTML = `<i class="bi bi-question-circle"></i>`
        }else if(submission.bet==0){
            this._betElement.innerHTML = `<i class="bi bi-send-exclamation"></i>`;
        }else{
            this._betElement.innerHTML = submission.bet.toString(10);
        }
        this._statusElement.innerHTML = UDICESubmissionsListItem.makeStatusHTML(submission.status);
        UDICESubmissionsListItem.setRollHTML(submission, this._rollElement);

        let timestamp = new Date(Number(await submission.createdTimestamp) * 1000);

        this._submissionTime.innerHTML = UDICEUtils.formatRelativeTime(timestamp);
        this._submissionTime.title = timestamp.toISOString();
        
        this._receivedCoinsAndTokensElement.innerHTML = await UDICESubmissionsListItem.makePayoutHTML(submission, this._session);
        if(submission.status==UDICESessions.SUBMISSION_STATUS_WON){
            this._receivedCoinsAndTokensElement.classList.add("badge", "bg-success");
            this._receivedCoinsAndTokensElement .classList.remove("bg-danger");
        }else{
            this._receivedCoinsAndTokensElement.classList.add("badge", "bg-danger");
            this._receivedCoinsAndTokensElement .classList.remove("bg-success");
        }
    }
}
customElements.define('udice-application-submissions-list-item', UDICESubmissionsListItem);

export class UDICESubmissionDetails extends HTMLElement{
    
    public static showDialog(session: UDICESessions.UDICESession, newValue : UDICESessions.UDICESubmission){
        let details:UDICESubmissionDetails = new UDICESubmissionDetails();
        details.setSubmission(session, newValue);

        let dialog:UDICEDialog = new UDICEDialog();

        dialog.titleContent = "#"+newValue.submissionId;
        dialog.bodyContent = details;

        dialog.show();
    }

    private _submissionIdElement: HTMLElement;
    private _blockNumberElement: HTMLElement;
    private _transactionHashElement: HTMLElement;
    private _timeElement: HTMLElement;
    private _playerElement: HTMLElement;
    private _wagerElement: HTMLElement;
    private _projectFeeElement: HTMLElement;
    private _multiplierElement: HTMLElement;
    private _betElement: HTMLElement;
    private _rollElement: HTMLElement;
    private _statusElement: HTMLElement;
    private _payoutElement: HTMLElement;

    constructor(){
        super();
        this.classList.add("table-responsive");

        this.innerHTML = `
            <table class="table table-striped">
                <tbody>
                    <tr><th class="pe-3">#</th><td class="submissionId text-start"></td></td>
                    <tr><th class="pe-3">Block Number</th><td class="blockNumber text-start"></td></td>
                    <tr><th class="pe-3">Transaction Hash</th><td class="transactionHash text-start"></td></td>
                    <tr><th class="pe-3">When</th><td class="timestamp text-start"></td></td>
                    <tr><th class="pe-3">Player</th><td class="player text-start"></td></td>
                    <tr><th class="pe-3">Wager</th><td class="wager text-start"></td></td>
                    <tr><th class="pe-3">Fee</th><td class="projectFee text-start"></td></td>
                    <tr><th class="pe-3">Multiplier</th><td class="multiplier text-start"></td></td>
                    <tr><th class="pe-3">Bet</th><td class="bet text-start"></td></td>
                    <tr><th class="pe-3">Roll</th><td class="roll text-start"></td></td>
                    <tr><th class="pe-3">Status</th><td class="status text-start"></td></td>
                    <tr><th class="pe-3">Payout</th><td class="payout text-start"></td></td>
                </tbody>
            </table>
        `;
        this._submissionIdElement = this.querySelector(".submissionId") as HTMLElement;
        this._blockNumberElement = this.querySelector(".blockNumber") as HTMLElement;
        this._transactionHashElement = this.querySelector(".transactionHash") as HTMLElement;
        this._timeElement = this.querySelector(".timestamp") as HTMLElement;
        this._playerElement = this.querySelector(".player") as HTMLElement;
        this._wagerElement = this.querySelector(".wager") as HTMLElement;
        this._projectFeeElement = this.querySelector(".projectFee") as HTMLElement;
        this._multiplierElement = this.querySelector(".multiplier") as HTMLElement;
        this._betElement = this.querySelector(".bet") as HTMLElement;
        this._rollElement = this.querySelector(".roll") as HTMLElement;
        this._statusElement = this.querySelector(".status") as HTMLElement;
        this._payoutElement = this.querySelector(".payout") as HTMLElement;
    }

    async setSubmission(session: UDICESessions.UDICESession, newValue : UDICESessions.UDICESubmission){
        this._submissionIdElement.innerHTML = newValue.submissionId.toString();
        this._blockNumberElement.innerHTML = newValue.blockNumber+"";
        //this._transactionHashElement.innerHTML = newValue.transactionHash;
        let timestamp = new Date(Number(await newValue.createdTimestamp) * 1000);
        this._timeElement.innerHTML = UDICEUtils.formatRelativeTime(timestamp);
        this._playerElement.innerHTML = "0x"+newValue.player;
        if(session.account.localeCompare(newValue.player, undefined, { sensitivity: 'accent' })===0){
            this._playerElement.innerHTML = "👤 " + this._playerElement.innerHTML;
        }
        this._wagerElement.innerHTML = session.fromWei(newValue.wager) +" "+ await session.getCoinsSymbol();
        this._projectFeeElement.innerHTML = session.fromWei(newValue.projectFee) + " " + await session.getCoinsSymbol();
        this._multiplierElement.innerHTML = session.fromWei(newValue.multiplier)+" X";
        this._betElement.innerHTML = newValue.bet.toString();
        this._statusElement.innerHTML = UDICESubmissionsListItem.makeStatusHTML(newValue.status);
        UDICESubmissionsListItem.setRollHTML(newValue, this._rollElement);

        this._payoutElement.innerHTML = await UDICESubmissionsListItem.makePayoutHTML(newValue, session);
        this._transactionHashElement.innerHTML = `<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>`;
        this._transactionHashElement.innerHTML = (await newValue.getTransactionInfo()).transactionHash;
    }

}
customElements.define('udice-application-submission-details', UDICESubmissionDetails);

export class UDICESubmissionsBrowser extends HTMLElement{

    private _session: UDICESessions.UDICESession;
    protected _addSubmissionsListenerSubscription: { unsubscribe: () => void; };
    private _button: HTMLButtonElement;
    protected _oldestSubmissionId: bigint;
    protected _newestSubmissionId: bigint;
    private _container: HTMLElement;
    private _items: Map<bigint, UDICESubmissionsListItem> = new Map();

    constructor(){
        super();
        
        this.innerHTML = `
            <div class="list-group all-list-group">
            </div>
            <div class="list-group all-list-group">
                <button class="list-group-item"><i class="bi bi-arrow-down-square"></i></button>
            </div>
        `;
        this._container = this.querySelector(".list-group") as HTMLElement;
        this._button = this.querySelector("button") as HTMLButtonElement;
        this._button.addEventListener("click", e=>{
            this._button.disabled = true;
            this.loadMoreSubmissions()
            .catch(error=>{
                console.log(error);
            })
            .finally(()=>{
                this._button.disabled = false;
                if(this._oldestSubmissionId <= BigInt(1)){
                    this._button.disabled = true;
                }
            })
        });
    }
    protected _querySubmissions(offset: number | bigint, limit: number | bigint, ascend: boolean): Promise<UDICESessions.UDICESubmission[]>{
        return this._session.submissions.querySubmissions(offset, limit, ascend);
    }
    protected _addSubmissionsListener(listener: (submission: UDICESessions.UDICESubmission) => void): { unsubscribe: () => void; }{
        return this._session.addSubmissionsListener(listener);
    }
    get session(): UDICESessions.UDICESession{
        return this._session;
    }
    set session(value: UDICESessions.UDICESession){
        if(value!=null){
            this.endSession();
        }
        this._session = value;

        if(this._session!=null){
            this.startSession();
        }
    }

    private findInsertionPoint(submission:  UDICESessions.UDICESubmission, item :UDICESubmissionsListItem){
        /*
        for(var i=0; i<this._container.children.length; i++){
            let top: UDICESessions.UDICESubmission = this._container.children[i]['submission'];
            if(top.submissionId < submission.submissionId){
                //the top item is older, insert above it
                return this._container.children[i];
            }
            let count = this._container.children.length;
            let bottom: UDICESessions.UDICESubmission = this._container.children[count-1-i]['submission'];
            if(bottom.submissionId > submission.submissionId){
                //the bottom item is newer, insert just bellow it
                return this._container.children[i].nextSibling;
            }
        }
        return null;
        */
        for(var i=0; i<this._container.children.length; i++){
            let top = this._container.children[i];
            if((top as UDICESubmissionsListItem).submission.submissionId < submission.submissionId){
                //the top item is older, insert above it
                //this._container.insertBefore(item, this._container.children[i])
                top.insertAdjacentElement('beforebegin', item);
                return this._container.children[i];
            }
            let count = this._container.children.length;
            let bottom = this._container.children[count-1-i];
            if((bottom as UDICESubmissionsListItem).submission.submissionId > submission.submissionId){
                //the bottom item is newer, insert just bellow it
                bottom.insertAdjacentElement('afterend', item);
                return this._container.children[i].nextSibling;
            }
        }
        this._container.appendChild(item);
        return null;
    }

    protected addSubmission(submission: UDICESessions.UDICESubmission){
        if(this._oldestSubmissionId==null || this._oldestSubmissionId > submission.submissionId){
            this._oldestSubmissionId = submission.submissionId;
        }
        if(this._newestSubmissionId==null || this._newestSubmissionId < submission.submissionId){
            this._newestSubmissionId = submission.submissionId;
        }
        var item = this._items.get(submission.submissionId);
        if(item==null){
            item = new UDICESubmissionsListItem(this._session);
            //this._container.insertBefore(item, this._container.firstElementChild);
            //this._container.insertBefore(item, this.findInsertionPoint(submission));
            this.findInsertionPoint(submission, item);
            this._items.set(submission.submissionId, item);
        }
        item.submission = submission;
    }

    protected startSession() {
        this._addSubmissionsListenerSubscription = this._addSubmissionsListener(submission=>{
            this.addSubmission(submission);
        });
        this.loadMoreSubmissions();
    }

    protected endSession() {
        if(this._addSubmissionsListenerSubscription!=null){
            this._addSubmissionsListenerSubscription.unsubscribe();
        }
        this._container.innerHTML = "";
        this._oldestSubmissionId = undefined;
        this._newestSubmissionId = undefined;
        this._items.clear();
    }

    protected async loadMoreSubmissions(pageSize: number=50){

        let submissions: UDICESessions.UDICESubmission[];
        if(this._oldestSubmissionId==null){
            submissions = await this._querySubmissions(0, pageSize, false);
            submissions.forEach(submission=>this.addSubmission(submission));
        }else{
            let offset: BigInt;
            let count: BigInt;
            if(this._oldestSubmissionId > BigInt(pageSize)){
                offset = this._oldestSubmissionId-BigInt(pageSize)-BigInt(1);
                count = BigInt(pageSize);
            }else{
                offset = BigInt(0);
                count = this._oldestSubmissionId-BigInt(1);
            }
            submissions = await this._querySubmissions(offset as bigint, count as bigint, true);
            submissions.reverse().forEach(submission=>this.addSubmission(submission));
        }
    }

}
customElements.define('udice-application-submissions-browser', UDICESubmissionsBrowser);

export class UDICEMySubmissionsBrowser extends UDICESubmissionsBrowser{
     constructor(){
        super();
     }

     protected _querySubmissions(offset: number | bigint, limit: number | bigint, ascend: boolean): Promise<UDICESessions.UDICESubmission[]>{
        return this.session.submissions.queryMySubmissions(offset, limit, ascend).then(submissions=>{

            return submissions.filter(item=>{
                if(item.player.toLowerCase()==this.session.account.toLowerCase()){
                    return true;
                }else{
                    return false;
                }
            });
        });
    }
    protected _addSubmissionsListener(listener: (submission: UDICESessions.UDICESubmission) => void): { unsubscribe: () => void; }{
        //return this.session.addMySubmissionsListener(listener);
        return this.session.addMySubmissionsListener((submission)=>{
            if(submission.player.toLowerCase()==this.session.account.toLowerCase()){
                listener(submission);
            }
        });
    }
}
customElements.define('udice-application-mysubmissions-browser', UDICEMySubmissionsBrowser);