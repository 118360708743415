import {analytics} from "./udice-app-analytics.ts";

//import {WallerProvidersSelector, NEW_SESSION_REQUEST_EVENT_ID} from './udice-component-select.js';
import {UDICEApplicationAuthenticator, ON_SESSION_CHANGED_EVENT_ID} from './udice-app-authenticator.ts';
import {UDICEApplicationPlayForm, UDICEApplicationBalance} from './udice-app-play-form.ts';
import {UDICESubmissionsList} from './udice-app-submissions.ts';
import {UDICESession} from "./udice-connect-session.ts";
import {UDICENav, UDICEPageFooter} from "./udice-app-nav.ts";
import { SplashScreen } from "./udice-app-utils.ts";
import { CountDown } from './udice-app-countdown.ts';
import { UDICERules, UDICERulesPoetry, showRulesPage } from "./udice-app-rules.ts";
import { ReferralManager } from "./udice-app-referrer.ts";

ReferralManager.init();

export class UDICEApplicationUi extends HTMLElement{
    authenticator: UDICEApplicationAuthenticator;
    form: UDICEApplicationPlayForm;
    submissions: UDICESubmissionsList;
    balances: UDICEApplicationBalance;
    _session: UDICESession | null;
    introduction: HTMLDivElement;
    notStarted: HTMLElement;
    private _countDown: CountDown;

    constructor(){
        super();

        new UDICERules();// dumy
        new UDICERulesPoetry(); //dummy
        UDICEApplicationPlayForm.dummy();//forces UDICEApplicationPlayForm to be included
        UDICESubmissionsList.dummy();
        new UDICEPageFooter();

        this.innerHTML = `
            <style>
                #udice-application-unconnected-container{
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 1;
                        background-color: #222222F0;
                        margin: 8px;
                }
                udice-application-authenticator{
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                        right: 16px;
                        left: 16px;
                }
            </style>
            <div id="application-container">
                <div class="d-flex justify-content-center">

                    <div  class="card mt-3 shadow col-xl-6 col-lg-8 col-md-10 col-sm-12 col-12">
                        <div class="card-body">
                            <div id="udice-application-unconnected-container">
                                <udice-application-authenticator></udice-application-authenticator>
                                <udice-countdown class="countdown d-none"></udice-countdown>
                            </div>
                            <div id="udice-application-connected-container">
                                <udice-application-balances></udice-application-balances>
                                <div class="alert alert-warning d-none" id="not-started">
                                    🚀 About to start, check back soon!
                                </div>
                                <udice-application-play-form></udice-application-play-form>
                            </div>
                        </div>
                    </div>

                </div>
                <udice-application-submissions-list></udice-application-submissions-list>

                <div class="d-flex justify-content-center">
                    <div id="introduction" class="card1 mt-4 col-xl-16 col-lg-8 col-md-10 col-sm-12 col-12">
                        <div class="card-body1">
                            <udice-application-rules-paragraph class="d-block mb-4"></udice-application-rules-paragraph>
                            
                            <!--
                            <a href="#page-info" class="nav-page btn btn-primary mb-3"><i class="bi bi-file-earmark-text"></i> Rules</a>
                            -->
                            <a href="#page-info" class="btn btn-primary mb-3 btn-rules"><i class="bi bi-file-earmark-text"></i> Rules</a>
                        </div>
                    </div>
                </div>
            </div>
        `;
        this._countDown = this.querySelector("udice-countdown") as CountDown;

        //this.applicationContainer = this.querySelector("#application-container");
        this.authenticator = this.querySelector("udice-application-authenticator") as UDICEApplicationAuthenticator;
        this.form = this.querySelector("udice-application-play-form") as UDICEApplicationPlayForm;
        this.submissions = this.querySelector("udice-application-submissions-list") as UDICESubmissionsList;
        this.balances = this.querySelector("udice-application-balances") as UDICEApplicationBalance;
        this.introduction = this.querySelector("#introduction") as HTMLDivElement;
        this.notStarted = this.querySelector("#not-started") as HTMLElement;
        
        this.session = null;
        this.authenticator.addEventListener(ON_SESSION_CHANGED_EVENT_ID, (event)=>{
            this.session = (event as CustomEvent).detail as UDICESession;
        });
        this.form.addEventListener(UDICEApplicationPlayForm.PLAYED_EVENT, e=>{
            this.balances.updateUi();
        });
        this.querySelector(".btn-rules").addEventListener("click", (e)=>{
            e.preventDefault();
            showRulesPage();
        });

        //this.selector = new WallerProvidersSelector();
        //this.applicationContainer.appendChild(this.selector);
        //this.selector.addEventListener(NEW_SESSION_REQUEST_EVENT_ID, event=>{
          //  this.session = event.detail;
        //});
        new CountDown();
    }

    get session(): UDICESession | null {
        return this._session;
    }

    set session(value: UDICESession | null){
        this._session = value;
        this.form.session = value;
        this.submissions.session = value;
        this.balances.session = value;

        if(value==null){
            //this.form.classList.add("d-none");
            this.submissions.classList.add("d-none");
            //this.balances.classList.add("d-none");
            this.introduction.classList.remove("d-none");
            this._countDown.style.setProperty("display", "none", "important");
            this.querySelector("#udice-application-unconnected-container").classList.remove("d-none");
        }else{
            //this.form.classList.remove("d-none");
            this.submissions.classList.remove("d-none");
            //this.balances.classList.remove("d-none");
            this.introduction.classList.add("d-none");
            this.querySelector("#udice-application-unconnected-container").classList.add("d-none");

            if(value.contractInfo.launchTime.getTime() >= Date.now()){
                this._countDown.style.display = null;
                this._countDown.date = value.contractInfo.launchTime;
                this.submissions.classList.add("d-none");
            }else{
                this._countDown.style.setProperty("display", "none", "important");
                this.submissions.classList.remove("d-none");
            }
        }
        this.checkContractState();
    }

    async checkContractState(){
        this.notStarted.classList.add("d-none"); 
        if(this._session==null) return;

        let state = await this._session.getState();

        if(state.submissionsCount==BigInt(0)){
            this.notStarted.classList.remove("d-none");
            this.session.addSubmissionsListener((submission)=>{
                this.notStarted.classList.add("d-none");
            });
        }else{
            this.notStarted.classList.add("d-none");
        }
    }

}
customElements.define('udice-application-ui', UDICEApplicationUi);

export class UDICESinglePageApp extends HTMLElement{

    _navBar: UDICENav;
    _application: UDICEApplicationUi;
    _root: ShadowRoot | HTMLElement;

    constructor(){
        super();
        this._navBar = new UDICENav();
        this._application = new UDICEApplicationUi();

        //this._root = this.attachShadow({mode: "open"});
        this._root = this;

        //this.showSplashScreen();
        this.initHtml();
    }

    initHtml() {
        this._root.innerHTML = `    
            <udice-application-navbar></udice-application-navbar>
            <div id="page-play-container" class="container">
                <div id="page-play" class="page-section">
                    <!--
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item active" aria-current="page">Play</li>
                        </ol>
                    </nav>
                    -->
                    <!--
                    <h1 class="display-5 border-bottom text-center">
                        <span style="white-space: nowrap">You dice,</span> 
                        <span style="white-space: nowrap">you house,</span> 
                        <span style="white-space: nowrap">yoo-doo!</span>
                    </h1>
                    -->
                    
                    <udice-application-ui></udice-application-ui>
    
                </div>
            </div>
            <div id="page-info-container" class="container d-none">
                <div id="page-info" class="page-section">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item active" aria-current="page"><a href="#page-play" class="nav-page">Play</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Rules</li>
                        </ol>
                    </nav>
                    <h1 class="border-bottom"><i class="bi bi-file-earmark-text"></i> House Rules</h1>
                    <udice-application-rules-poetry></udice-application-rules-poetry>
                    
                    <hr />

                    Okay, enough with AI:

                    <udice-application-rules-summary></udice-application-rules-summary>

                    <a href="#page-play" class="nav-page btn btn-primary btn-lg mb-4">Play</a>
                </div>
            </div>
        `;
    
        this._navBar = this._root.querySelector("udice-application-navbar") as UDICENav;
    
        let pagePlayContainer = this._root.querySelector("#page-play-container") as HTMLElement;
        let pageInfoContainer = this._root.querySelector("#page-info-container") as HTMLElement;
    
        this._navBar.addNavLink("page-play", "<i class='bi bi-dice-5'></i> Play", true, pagePlayContainer);
        //this._navBar.addNavLink("page-info", "Rules", false, pageInfoContainer);
        this._navBar.addNavLinkAction("page-info", "<i class='bi bi-file-earmark-text'></i> Rules", ()=>showRulesPage());
    }

}
customElements.define('udice-application-spa', UDICESinglePageApp);
