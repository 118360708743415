import {EstimatePlayResult, UDICESession} from "./udice-connect-session.js";
import { default as Tooltip } from "./../../node_modules/bootstrap/js/src/tooltip.js";
import {default as Popover} from "./../../node_modules/bootstrap/js/src/popover.js";
import {BigNumber} from "bignumber.js";
//import {default as BootstrapModal} from "bootstrap/js/src/modal.js";
import {ProgressScreen, UDICEDialog, UDICEUtils} from "./udice-app-utils.ts";
import { GetStateResult } from "./udice-connect-contract.ts";
import { ChainInfo, IUdiceValuer } from "./udice.config.ts";
import { UDICERulesButton, showRulesPage } from "./udice-app-rules.ts";
import {ReferralManager as ReferralManager} from "./udice-app-referrer.ts";
import {default as Dropdown} from "./../../node_modules/bootstrap/js/src/dropdown.js";

    function styleRangeThumb(rangeInput) {
        // Disable default styling
            rangeInput.style.webkitAppearance = 'none';
            // Update thumb style initially
            updateThumbStyle(rangeInput);
        
            // Add event listener to update thumb style on input change
            rangeInput.addEventListener('input', function() {
                updateThumbStyle(rangeInput);
            });
            rangeInput.addEventListener('change', function() {
                updateThumbStyle(rangeInput);
            });
        }
        
    function updateThumbStyle(rangeInput) {
            // Get the value and range of the input
            var value = rangeInput.value;
            var min = rangeInput.min || 0;
            var max = rangeInput.max || 100;
        
            // Calculate percentage value
            var percentage = (value - min) / (max - min) * 100;
        
            // Set background gradient for the thumb
            let style = 'linear-gradient(to right, rgb(220, 53, 69) ' + percentage + '%, rgb(25, 135, 84) ' + percentage + '%)';
        
            rangeInput.style.background = style;
        }
const FEE_ON_THE_HOUSE_LOCAL_STORAGE_PARAM_NAME = "udice-app-play-form-feeOnTheHouse";

/*
function readDefaultFeeOnTheHouse(valueIfUndefined:boolean){
    if(!window.localStorage) return valueIfUndefined;
    return window.localStorage.getItem(FEE_ON_THE_HOUSE_LOCAL_STORAGE_PARAM_NAME)=="1";
}
function writeDefaultFeeOnTheHouse(value:boolean){
    if(!window.localStorage) return false;
    window.localStorage.setItem(FEE_ON_THE_HOUSE_LOCAL_STORAGE_PARAM_NAME, "1");
}
*/

function readDefaultPlayInputValue(chainId: number, valueIfUndefined:  UDICEInputValue) : UDICEInputValue{
    if(!window.localStorage) return valueIfUndefined;
    if(!window.localStorage.getItem(`udice-application-play-form-input-value-amount-${chainId}`)) return valueIfUndefined;
    if(!window.localStorage.getItem(`udice-application-play-form-input-value-multiplier-${chainId}`)) return valueIfUndefined;
    if(!window.localStorage.getItem(`udice-application-play-form-input-value-feeOnTheHouse-${chainId}`)) return valueIfUndefined;
    return {
        amount: BigInt(window.localStorage.getItem(`udice-application-play-form-input-value-amount-${chainId}`)),
        multiplier: BigInt(window.localStorage.getItem(`udice-application-play-form-input-value-multiplier-${chainId}`)),
        feeOnTheHouse: window.localStorage.getItem(`udice-application-play-form-input-value-feeOnTheHouse-${chainId}`)=="1"?true:false
    };
}
function writeDefaultPlayInputValue(chainId: number, value: UDICEInputValue){
    if(!value){
        window.localStorage.removeItem(`udice-application-play-form-input-value-amount-${chainId}`);
        window.localStorage.removeItem(`udice-application-play-form-input-value-multiplier-${chainId}`);
        window.localStorage.removeItem(`udice-application-play-form-input-value-feeOnTheHouse-${chainId}`);
    }else{
        window.localStorage.setItem(`udice-application-play-form-input-value-amount-${chainId}`, value.amount.toString(10));
        window.localStorage.setItem(`udice-application-play-form-input-value-multiplier-${chainId}`, value.multiplier.toString());
        window.localStorage.setItem(`udice-application-play-form-input-value-feeOnTheHouse-${chainId}`, value.feeOnTheHouse?"1":"0");
    }
}


class UDICEApplicationPlayButtonIcon extends HTMLElement{
    private _timerId: any;
    private _items: HTMLElement[];

    static dummy(){

    }
    constructor(){
        super();
        this.innerHTML = `
            <i class="bi bi-dice-1 d-none"></i>
            <i class="bi bi-dice-2 d-none"></i>
            <i class="bi bi-dice-3 d-none"></i>
            <i class="bi bi-dice-4 d-none"></i>
            <i class="bi bi-dice-5 d-none"></i>
            <i class="bi bi-dice-6"></i>        
        `;
        this._items = Array.from(this.querySelectorAll("i"));
    }

    toggle(){
        let next = this['_p']==null?0:(this['_p']+1)%this._items.length;
        for(var i=0; i<this._items.length; i++){
            this._items[i].classList.add("d-none");
        }
        this._items[next].classList.remove("d-none");
        this['_p'] = next;
    }

    async shuffle(): Promise<void>{
        //let count:number = Math.round(Math.random()*6);
        //var c: number = count*3;
        var rounds:number = Math.round(Math.random()*18);
        let timerId: any;
        let result = Promise.withResolvers<void>();
        timerId = setInterval(()=>{
            this.toggle();
            rounds--;
            if(rounds<=0){
                clearInterval(timerId);
                result.resolve(null);
            }
        }, 200);
        return result.promise;
    }

    connectedCallback(){
        if(this._timerId!=null) return;
        this._timerId = setInterval(()=>{
            if(this["_paf"]!=null){
                window.cancelAnimationFrame(this["_paf"]);
            }
            this["_paf"] = window.requestAnimationFrame(()=>{
                this.shuffle();
            });
        }, 10000);
    }

    disconnectedCallback(){
        clearInterval(this._timerId);
        this._timerId = undefined;
    }
}
UDICEApplicationPlayButtonIcon.dummy();
customElements.define('udice-application-play-button-icon', UDICEApplicationPlayButtonIcon);

export class UDICEApplicationPlayForm extends HTMLElement {
    static readonly PLAYED_EVENT = "played";
    private _swapDropdown: HTMLButtonElement;
    

    static dummy(){}

    _playInput: IUDICEPlayInput;
    private _estimatesOutput: UDICEPlayerEstimatesOutput;

    _playButton: HTMLButtonElement;
    _tokenomicsButton: HTMLButtonElement;
    _contractCodeButton: HTMLAnchorElement;
    private _buyButton: HTMLAnchorElement;
    private _sellButton: HTMLAnchorElement;

    _session?: UDICESession;

    constructor() {
        super();
        UDICERulesButton.dummy();
        this.innerHTML = `
            <udice-application-play-input></udice-application-play-input>
            
            <udice-application-estimates-output></udice-application-estimates-output>

            <div class="mt-2">
                <button type="button" class="btn btn-primary btn-lg btn-play">
                    <udice-application-play-button-icon></udice-application-play-button-icon>
                    Play
                </button>

                <button class="btn btn-dark btn-sm btn-play-form-rules"><i class="bi bi-file-earmark-text"></i> Rules</button>

                <button type="button" class="btn btn-dark btn-sm btn-tokenomics">
                    <i class="bi bi-sliders2-vertical"></i> Tokenomics
                </button>

                <a type="button" class="btn btn-dark btn-sm btn-contract" href="" target="_blank">
                    <i class="bi bi-file-earmark-code"></i> Contract
                </a>

                <div class="dropdown dropdown-swap d-inline">
                    <button class="btn btn-dark btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-shuffle"></i> Swap
                    </button>
                    <ul class="dropdown-menu">
                        <li>
                            <a type="button" class="dropdown-item btn-buy" href="">
                                <i class="bi bi-cart-plus"></i> Buy UDUH
                            </a>
                        </li>
                        <li>
                            <a type="button" class="dropdown-item btn-sell" href="">
                                <i class="bi bi-cart-dash"></i> Sell UDUH
                            </a>
                        </li>
                    </ul>
                </div>

            </div>
        `;
        //this._playInput = new UDICEPlayInput();
        this._playInput = this.querySelector("udice-application-play-input") as IUDICEPlayInput;
        this._estimatesOutput = this.querySelector("udice-application-estimates-output") as UDICEPlayerEstimatesOutput;

        //this.appendChild(this._playInput);
        
        this._playInput.addEventListener("change", e=>{
            this.updateEstimates();

            if(this._session!=null){
                writeDefaultPlayInputValue(this._session.chainId, this._playInput.value);
            }
        })

        //this.balancesElement = this.querySelector("udice-application-balances");
        this._playButton = this.querySelector(".btn-play") as HTMLButtonElement;
        this._tokenomicsButton = this.querySelector(".btn-tokenomics") as HTMLButtonElement;
        this._contractCodeButton = this.querySelector(".btn-contract") as HTMLAnchorElement;
        this._swapDropdown = this.querySelector(".dropdown-swap") as HTMLButtonElement;
        this._buyButton = this.querySelector(".btn-buy") as HTMLAnchorElement;
        this._sellButton = this.querySelector(".btn-sell") as HTMLAnchorElement;
        const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
        const dropdownList = [...dropdownElementList].map(dropdownToggleEl => new Dropdown(dropdownToggleEl))

        this._playButton.addEventListener("click", () => {
            this.initiatePlay();
        });
        this._tokenomicsButton.addEventListener("click", ()=>{
            UDICEApplicationTokenomicsInfo.show(this.session);
        });
        this.querySelector(".btn-play-form-rules").addEventListener("click", e=>{
            showRulesPage();
        })
        this.session = null;
    }

    get session (): UDICESession | null {
        return this._session;
    }
    set session(value: UDICESession | null) {
        if(this._session!=null){
            if(this['_addContractEventsListenerSubscription']!=null){
                this['_addContractEventsListenerSubscription'].unsubscribe();
            }
        }
        this._session = value;

        if(this._session==null){
            this._contractCodeButton.href = "";
            this._contractCodeButton.classList.add("d-none");
            this._buyButton.classList.add("d-none")
            this._sellButton.classList.add("d-none")
        }else{
            let contractUrl = this._session.contractUrl();
            this._contractCodeButton.href = contractUrl;
            if(contractUrl==null || contractUrl==""){
                this._contractCodeButton.classList.add("d-none");
            }else{
                this._contractCodeButton.classList.remove("d-none");
            }
            if(value.contractInfo.buyUrl || value.contractInfo.sellUrl){
                this._swapDropdown.classList.remove("d-none");
                if(value.contractInfo.buyUrl){
                    this._buyButton.classList.remove("d-none")
                    this._buyButton.href = value.contractInfo.buyUrl;
                }else{
                    this._buyButton.classList.add("d-none")
                    this._buyButton.href = "";
                }
                if(value.contractInfo.sellUrl){
                    this._sellButton.classList.remove("d-none")
                    this._sellButton.href = value.contractInfo.sellUrl;
                }else{
                    this._sellButton.classList.add("d-none")
                    this._sellButton.href = "";
                }

            }else{
                this._swapDropdown.classList.add("d-none")
            }
        }
        this._playInput.session = value;
        this.updateUiState(value);
        this.updateEstimates();
        this.initializeAmount()
            .finally(()=>this.updateEstimates());

        //this._session.addConfigurationChangedEventListener
        //this._session.addStateChangedEventListener
        //this._session.addSubmissionsListener();
        if(this._session!=null){
            this['_addContractEventsListenerSubscription'] = this._session.addContractEventsListener(()=> this.updateEstimates());
        }
    }
    async initialPlayInputValue(){

    }

    async initializeAmount(){
        if(this._session!=null /*&& this.amountTextBox.value == ""*/){
            let wager;
            if(this._session.contractInfo.defaultWager!=null){
                wager = this._session.contractInfo.defaultWager;
            }else{
                wager = await this._session.computeMinimumRecommendedWager();
            }
            
            //let feeOnTheHouse: boolean = readDefaultFeeOnTheHouse(true);
            //this._playInput.value = {amount: wager, multiplier: BigInt(2)*this._session.ether, feeOnTheHouse:feeOnTheHouse};
            let defaultValue = {amount: wager, multiplier: BigInt(2)*this._session.ether, feeOnTheHouse:true};
            this._playInput.value = readDefaultPlayInputValue(this._session.chainId, defaultValue);
        }
    }

    async updateUiState(session?: UDICESession) {
        let launched = session!=null && session.contractInfo.launchTime.getTime() < Date.now();
        
        let value = session;
        //this.amountTextBox.disabled = value==null;
        //this.feeOnTheHouseCheckBox.disabled = value==null;
        //this.multiplierRange.disabled = value==null;
        //this.multiplierNumber.disabled = value==null;
        this._playButton.disabled = value==null || !launched;
        this._tokenomicsButton.disabled = value==null || value.contractInfo.contractAddress==null || value.contractInfo.contractAddress=="";

        if(value==null || value.contractInfo.contractAddress==null || value.contractInfo.contractAddress==""){
            this._contractCodeButton.classList.add("disabled");
        }else{
            this._contractCodeButton.classList.remove("disabled");
        }

        if(!launched){
            //this._playInput.classList.add("d-none");
            //this._estimatesOutput.classList.add("d-none");
        }else{
            //this._playInput.classList.remove("d-none");
            //this._estimatesOutput.classList.remove("d-none");
        }
    }

    async updateEstimates() {
        if (this.session==null) {
            this._estimatesOutput.setEstimates(this.session, null);
            return;
        }

        let value = this._playInput.value;
        let estimates = await this.session.estimatePlayByMultiplier(value);

        this._estimatesOutput.setEstimates(this.session, estimates);
    }

    async promptConfirmation(amount:bigint, multiplier:bigint, feeOnTheHouse:boolean): Promise<boolean>{
        let estimation = await this.session.estimatePlayByMultiplier({amount, multiplier, feeOnTheHouse});

        if(estimation.multiplierBoostTokens == BigInt(0)){
            return true;
        }
        let tokensBalance = await this.session.getAccountTokensBalance();
        let tokenSymbol = await this.session.getTokensSymbol();

        let dialog: UDICEDialog = new UDICEDialog();
        dialog.fullscreen = false;
        dialog.titleContent = "Token Burn Confirmation";
        dialog.bodyContent = document.createElement("span");
        dialog.bodyContent.innerHTML = "Burning token: "+this.session.fromWei(estimation.multiplierBoostTokens)+" "+tokenSymbol
            +"<br/>You tokens balance: "+this.session.fromWei(tokensBalance)+" "+tokenSymbol;

        if(estimation.multiplierBoostTokens <= tokensBalance){
            dialog.footerAppendableContent = document.createElement("button");
            dialog.footerAppendableContent.innerHTML = `Continue <i class="bi bi-arrow-right-square"></i>`;
            dialog.footerAppendableContent.classList.add("btn", "btn-danger");
        }else{
            dialog.footerAppendableContent = document.createElement("div");
            dialog.footerAppendableContent.innerHTML = `You do not have sufficient tokens to burn`;
            dialog.footerAppendableContent.classList.add("alert", "alert-warning");
        }
        
        dialog.show();

        let result = Promise.withResolvers<boolean>();

        dialog.footerAppendableContent.addEventListener("click", e=>{
            result.resolve(true);
            dialog.close();
        });
        dialog.onClosed = ()=>{
            result.resolve(false);
        };
        
        return result.promise;
    }

    async initiatePlay() {
        if(this.session==null) throw new Error("Session not assigned yet");

        let p = ProgressScreen.show();
        this._playButton.disabled = true;
        try {
            //let amount = BigInt(this.session.toWei(this.amountTextBox.value));
            //let multiplier = BigInt(this.session.toWei(this.multiplierNumber.value));
            //let prepaidProjectFee = BigInt(this.session.toWei(this.feeOnTheHouseCheckBox.checked ? "0" : "1000000"));

            let v = this._playInput.value;

            if(! (await this.promptConfirmation(v.amount, v.multiplier, v.feeOnTheHouse))){
                return;
            }

            let submission = await this.session.play(v.amount, v.multiplier, v.feeOnTheHouse);

            //let submission = await playMethodTransaction.getSubmission();

            console.log("Submission: "+submission);

            let submitted = await submission.getSubmittedEvent();
            console.log("Submitted: "+submitted);

            //let outcome = await submission.getOutcomeEvent();
            //console.log("Outcome: "+outcome);
            let completed = await submission.onComplete();
            console.log("Completed: "+completed);

            //console.log("All: "+await submission.resolve());
            this.updateEstimates();
        } finally {
            this._playButton.disabled = false;
            p.remove();
            this.firePlayCompletedEvent();
        }

    }
    firePlayCompletedEvent() {
        let event = new CustomEvent(UDICEApplicationPlayForm.PLAYED_EVENT)
        this.dispatchEvent(event);
    }
}
customElements.define('udice-application-play-form', UDICEApplicationPlayForm);

class BalanceInfo extends HTMLElement{
    private _jackpotBalanceFiat: HTMLElement;

    public static showDialog(session:UDICESession){
        let dialog:UDICEDialog = new UDICEDialog();

        let content = new BalanceInfo();
        content.updateBalance(session);

        dialog.titleContent = "Balance Information";
        dialog.bodyContent = content;
        dialog.show();
    }

    private _coinsBalance: HTMLElement;
    private _tokensBalance: HTMLElement;
    private _jackpotBalance: HTMLElement;

    constructor(){
        super();
        
        this.innerHTML = `
            <table class="table table-striped">
                <tbody>
                    <tr><th>Your Coins Balance</th><td id="coins-balance"></td></tr>
                    <tr><th>Your Tokens Balance</th><td id="tokens-balance"></td></tr>
                    <tr><th>Jackpot Balance</th><td id="jackpot-balance"></td></tr>
                    <tr><th>Jackpot Fiat</th><td id="jackpot-balance-fiat"></td></tr>
                </tbody>
            </table>
        `;
        this._coinsBalance = this.querySelector("#coins-balance") as HTMLElement;
        this._tokensBalance = this.querySelector("#tokens-balance") as HTMLElement;
        this._jackpotBalance = this.querySelector("#jackpot-balance") as HTMLElement;
        this._jackpotBalanceFiat = this.querySelector("#jackpot-balance-fiat") as HTMLElement;
    }

    async updateBalance(session:UDICESession){
        this._coinsBalance.innerHTML =  session.fromWei(await session.getAccountCoinsBalance())+" "+session.getCoinsSymbol();
        this._tokensBalance.innerHTML = session.fromWei(await session.getAccountTokensBalance())+" "+ await session.getTokensSymbol();
        let state = await session.getState();
        this._jackpotBalance.innerHTML = session.fromWei(state.rewardPool)+" "+session.getCoinsSymbol();
        this._jackpotBalanceFiat.innerHTML = await session.coinsToFiatFormatted(state.rewardPool);
    }
}
customElements.define('udice-application-balance-info', BalanceInfo);

export class UDICEApplicationBalance extends HTMLElement{
    userCoinsBalanceElement: HTMLElement;
    userTokensBalanceElement: HTMLElement;
    rewardPoolBalanceElement: HTMLElement;
    _session : UDICESession;
    stateChangedEventSubscription: any;
    private _balanceInfoBtn: any;
    rewardPoolBalanceFiatElement: HTMLElement;
    constructor(){
        super();

        //text-center fs-5 row
        this.classList.add('text-center', 'fw-bold');

        this.innerHTML = `

            <div class="display-6 border1 mt-2 shadow p-1 bg-secondary text-white rounded" title="JackPot">
                <span id="user-reward-pool-balance">&nbsp;</span>
                <span class="coins-symbol"></span>
                💰
            </div>
            <div class="text-center fw-light mt-2 p-2 text-bg-dark">
                Your Balance: 
                <span class="badge bg-success">
                    <span id="user-tokens-balance">&nbsp;</span>
                    <span class="tokens-symbol"></span>
                </span>
                <span class="badge text-bg-secondary">
                    <span id="user-coins-balance">&nbsp;</span>
                    <span class="coins-symbol"></span>
                </span>
            </div>
        `;
        this.innerHTML = `
        <button id="btn-balance-info" class="btn btn-dark col-12 rounded p-1 mt-1">
            <div class="m-1 clearfix rounded">
                <span class="badge bg-success float-start">
                    <span id="user-tokens-balance">&nbsp;</span>
                    <!-- <span class="tokens-symbol"></span> -->
                </span>
                <span class="badge text-bg-secondary float-end">
                    <span id="user-coins-balance">&nbsp;</span>
                    <!-- <span class="coins-symbol"></span> -->
                </span>
                 💰
                <div class="display-6 border1 m-1 shadow p-1 text-white rounded fw-bold" title="JackPot" style="word-break: keep-all;word-wrap: normal;">
                    <span id="user-reward-pool-balance">&nbsp;</span>
                    <!-- <span class="coins-symbol"></span> -->
                    <span class="btn float-end d-none" style="font-size: 0.5em">
                        💰  <i class="bi bi-question-circle"></i>
                    </span>
                </div>
                <span id="user-reward-pool-balance-fiat"></span>
            </div>
        </button>
        `;

        this.userCoinsBalanceElement = this.querySelector("#user-coins-balance");
        this.userTokensBalanceElement = this.querySelector("#user-tokens-balance");
        this.rewardPoolBalanceElement = this.querySelector("#user-reward-pool-balance");
        this.rewardPoolBalanceFiatElement = this.querySelector("#user-reward-pool-balance-fiat");
        
        this._balanceInfoBtn = this.querySelector("#btn-balance-info");
        //this.classList.add("d-none");
        this._balanceInfoBtn.addEventListener("click", e=>{
            BalanceInfo.showDialog(this.session);
            this._updateUi(this.session);
        })
        this._balanceInfoBtn.addEventListener("click", e=>{
            let p = ProgressScreen.show();
            this._updateUi(this.session).finally(()=>p.remove());
        })
    }

    get session() : UDICESession{
        return this._session;
    }

    set session(value : UDICESession){
        if(this.stateChangedEventSubscription){
            this.stateChangedEventSubscription.unsubscribe();
        }
        this._session = value;

        if(this._session!=null){
            this.stateChangedEventSubscription = this._session.addContractEventsListener(event=>{
                this._updateUi(value);
            });
        }
        this._updateUi(value);
    }

    public updateUi(){
        this._updateUi(this._session);
    }

    private async _updateUi(session: UDICESession, state?:GetStateResult){
        if(session==null){
            //this.classList.add("d-none");
            this.userCoinsBalanceElement.innerHTML = '&nbsp;-';
            this.userTokensBalanceElement.innerHTML = '&nbsp;-';
            this.rewardPoolBalanceElement.innerHTML = '&nbsp;-';
            this.rewardPoolBalanceFiatElement.innerHTML = '&nbsp;-';;
            this.rewardPoolBalanceFiatElement.style.visibility = "hidden"
            this._balanceInfoBtn.disabled = true;
            return;
        }else{
            this._balanceInfoBtn.disabled = false;
        }

        //this.classList.remove("d-none");
        let coinsSymbol = session.getCoinsSymbol();
        let tokenSymbol = await session.getTokensSymbol();

        if(state==null){
            state = await session.getState();
        }
        this.rewardPoolBalanceElement.innerHTML = session.fromWei(state.rewardPool)+" "+coinsSymbol;
        session.coinsToFiatFormatted(state.rewardPool).then(result=>{
            if(result==null){
                this.rewardPoolBalanceFiatElement.style.visibility = "hidden";
            }else{
                this.rewardPoolBalanceFiatElement.innerHTML = result;
                this.rewardPoolBalanceFiatElement.style.visibility = "";
            }
            
        })
        /*
        if(state!=null){
            this.rewardPoolBalanceElement.innerHTML = session.fromWei(state.rewardPool)+" "+coinsSymbol;
        }else{
            session.getState().then(state=>{
                this.rewardPoolBalanceElement.innerHTML = session.fromWei(state.rewardPool);
            });
        }
        */
        session.getAccountCoinsBalance().then(balance=>{
            this.userCoinsBalanceElement.innerHTML = session.fromWei(balance)+" "+coinsSymbol;
        });
        session.getAccountTokensBalance().then(balance=>{
            this.userTokensBalanceElement.innerHTML = session.fromWei(balance)+" "+tokenSymbol;
        });

        this.querySelectorAll(".coins-symbol").forEach(item=> {
            item.innerHTML = coinsSymbol;
        });

        session.getTokensSymbol().then(symbol=>{
            this.querySelectorAll(".tokens-symbol").forEach(item=>{
                item.innerHTML = tokenSymbol;
            });
        });
        
    }

}
customElements.define('udice-application-balances', UDICEApplicationBalance);

export class UDICEApplicationTokenomicsInfo extends HTMLElement{

    static async show(session: UDICESession){
        let info: UDICEApplicationTokenomicsInfo = new UDICEApplicationTokenomicsInfo(session);

        /*
        let wrapper = document.createElement('div');
        wrapper.innerHTML = `
            <div class="modal fade" tabindex="-1">
                <div class="modal-dialog modal-fullscreen modal-dialog-scrollable modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title">Tokenomics</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>Modal body text goes here.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
                </div>
            </div>        
        `;
        let modalElement = wrapper.children.item(0);
        document.querySelector("body").appendChild(modalElement);
        
        modalElement.querySelector(".modal-body").innerHTML = ``;
        modalElement.querySelector(".modal-body").appendChild(info);

        const modal = new BootstrapModal(modalElement, {backdrop: true, focus: true, keyboard: true});
        modal.show();

        modalElement.addEventListener("hidden.bs.modal", e=>{
            modal.dispose();
        });
        */
        let dialog: UDICEDialog = new UDICEDialog();
        dialog.titleContent = "Tokenomics";
        dialog.bodyContent = info;
        dialog.show();
    }

    _session: UDICESession;
    container: HTMLElement;

    constructor(session: UDICESession){
        super();
        this._session = session;
        this.innerHTML = '';
        this.container = this;

        this.innerHTML = `
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div> 
        `;
        this.updateUi();
    }

    private toPercentDisplay(numerator: bigint, denominator:bigint){
        let percentNumerator = new BigNumber((numerator).toString(10));
        let percentDenominator = new BigNumber(denominator.toString(10));
        let result = percentNumerator.multipliedBy(100).div(percentDenominator);
        return result.toNumber().toLocaleString(undefined, {maximumSignificantDigits: 3, maximumFractionDigits:2});
    }

    async updateUi(){
        let config = await this._session.getConfiguration();
        let state = await this._session.getState();
        let coinsSymbol: string = this._session.getCoinsSymbol();
        let tokensSymbol: string = await this._session.getTokensSymbol();
        
        let epoch = state.submissionsCount < 1 ? BigInt(0):(state.submissionsCount-BigInt(1))/config.submissionsPerEpoch;

        let originTokenIssuanceRate = this._session.fromWei(config.maxRewardTokensPerEth);
        let currentTokenIssuanceRate = this._session.fromWei(config.maxRewardTokensPerEth >> epoch);

        let houseEdgeItems = [];
        if(config.houseEdgeFixed > 0) houseEdgeItems.push(`${this._session.fromWei(config.houseEdgeFixed)} ${coinsSymbol} / bet`);
        if(config.houseEdgeRate > 0){
            let houseEdgePercent = this.toPercentDisplay(config.houseEdgeRate, this._session.ether);
            //houseEdgeItems.push(`${this._session.fromWei(config.houseEdgeRate)} ${coinsSymbol} / 1 ${coinsSymbol} bet (${houseEdgePercent}%)`);
            houseEdgeItems.push(`${houseEdgePercent}%`);
        }
        let houseEdgeValue = houseEdgeItems.join(" + ");

        //let withdrawalStartRate = BigInt(100)*(BigInt(10)**BigInt(18));
        //let withdrawalFeePercent = new BigNumber((withdrawalStartRate >> BigInt(epoch)).toString(10)).div(BigNumber((BigInt(10)**BigInt(18)).toString(10)));
        //let withdrawalFeePercent = withdrawalFeePercent.toNumber().toLocaleString(undefined, {minimumSignificantDigits: 4});
        let withdrawalFeePercent = this.toPercentDisplay(this._session.ether >> BigInt(epoch), this._session.ether);
        
        
        let tokenValue;
        if(state.totalSupply==BigInt(0)){
            tokenValue = BigInt(0);
        }else{
            let tokenValueBN :BigNumber = new BigNumber(state.rewardPool.toString(10)).div(new BigNumber(state.totalSupply.toString(10))).multipliedBy(new BigNumber("10").pow(this._session.contractInfo.chain.nativeCurrency.decimals));
            tokenValue = BigInt(tokenValueBN.decimalPlaces(0).toString(10));
        }

        let properties = [
            {name: "Epoch Size", value: `${config.submissionsPerEpoch} bets`,
                description: `Number of bets after which issued tokens are cut by half`},
            {name: "House Edge", value: `${houseEdgeValue}`, 
                description: `The statistical advantage of the house on player, which is esentially a statistical house earnings. All earnings go to the the ${coinsSymbol} jackpot.`},
            {name: "Token Issuance", value: `${currentTokenIssuanceRate} ${tokensSymbol} / 1 ${coinsSymbol}`,
                description: `
                <p>
                    If bet is lost, player receive ${currentTokenIssuanceRate} ${tokensSymbol} for each 1 ${coinsSymbol} of house edge.
                    If the player wins, those tokens are issued to project instead. 
                </p>
                <p>
                    If a player wins and the jackpot is not enough to cover their winnings, 
                    the player receives the wager back, and they also receive ${currentTokenIssuanceRate} ${tokensSymbol} for each 1 ${coinsSymbol} of the rest of the win amount.
                <p>
                <p>
                    Token issuance rate starts at ${originTokenIssuanceRate} ${tokensSymbol} / 1 ${coinsSymbol} and is halved after every epoch.
                </p>`},
            //{name: "Minimum Jackpot", value: `${this._session.fromWei(config.minimumRewardPool)} ${coinsSymbol}`,
                // description: `If the jackpot is below this, all bets are considered donations in exchange for tokens. Tokens issued are ${this._session.fromWei(config.maxRewardTokensPerEth)} ${tokensSymbol} / 1 ${coinsSymbol} wager`},
            //{name: "Transfer Gas Limit", value: `${config.transferGasLimit} gas`,
              //  description: `Gas limit for sending payouts. If the receiver reverts, the payout is stored as player credits and can be withdrawn manually`},
            {name: "Project Fee", value: `${this._session.fromWei(config.projectFee)} ${coinsSymbol} / 1 bet`, 
                description: `Fixed fee charged per bet to cover project costs. You may pay upfront, or let the house cover the cost in exchange for a slight increase in house edge.`},
            {name: "Jackpot", value: `${this._session.fromWei(state.rewardPool)} ${coinsSymbol}`, 
                description: `Total ${coinsSymbol} available:
                    <ol>
                        <li>to service winning bets, and</li>
                        <li>to honor withdrawals in exchange for token burns, subject to withdrawal fee</li>
                    <ol>`},
            {name: "Jackpot Withdrawal Free", value: `${withdrawalFeePercent}%`,
                description: `Fee charged when ${tokensSymbol} tokens are burnt in exchange for ${coinsSymbol} coins from the jackpot. This is cut in half on every new epoch. It starts at 100% on the 0th epoch and reaches 0% on the 66th epoch. (This is not a fee on winnings, winnings are always received in full)`
            },
            {name: "Token Withdrawal Value", value: `${this._session.fromWei(tokenValue)} ${coinsSymbol} / ${tokensSymbol}`,
                description: `The amount of ${coinsSymbol} obtained from the jackpot in exchange of 1 ${tokensSymbol} on jackpot withdrawal`},

            {name: "Total Supply", value: `${this._session.fromWei(state.totalSupply)} ${tokensSymbol}`,
                description: `Total number of tokens in circulation`},
            {name: "Treasury", value: `${this._session.fromWei(state.treasuryPool)} ${coinsSymbol} + ${this._session.fromWei(state.treasuryTokens)} ${tokensSymbol}`,
                description: `Project treasury resources available to the team`, id:"udice-tokenomics-treasury"},

            {name: "Credit Pool", value: `${this._session.fromWei(state.creditPool)} ${coinsSymbol}`,
                description: `Total of coins available to players pending manual withdraw`},
            
        ];
        this.container.innerHTML = properties.map(item=>{
            let id = item.id?'id="'+item.id+'"':'';
            return `
                <div class="container border p-2" ${id}>
                    <div class="row">
                        <div class="col-sm-4">${item.name}</div>
                        <div class="col-sm-8">${item.value}</div>
                    </div>
                    <div class="row form-text"><div class="col">${item.description}</div></div>
                </div>
            `;
        }).join("\n");

        this.querySelector("#udice-tokenomics-treasury").addEventListener("dblclick", e=>{
            this.startOracleDepositFromTreasury(state.treasuryPool);
        });
    }

    async startOracleDepositFromTreasury(treasuryPool: bigint) {

        let amount = prompt("Amount", this._session.fromWei(treasuryPool));
        if(amount==null) return;

        let value = this._session.toWei(amount);

        let receipt = await this._session.contract.methods.makeOracleDepositFromTreasury(value).send();

        console.log(receipt);

        alert("Done: " + receipt);
    }

}
customElements.define('udice-application-tokenomics', UDICEApplicationTokenomicsInfo);


export interface IUDICEPlayInput extends HTMLElement{
    get session():UDICESession;
    set session(v:UDICESession);
    get value(): UDICEInputValue;
    set value(newValue: UDICEInputValue);
}

export interface UDICEInputValue{
    readonly amount: bigint;
    readonly multiplier: bigint;
    readonly feeOnTheHouse: boolean;
}
function parseEthers(session:UDICESession, value:string) {
    return BigInt(session.toWei(value.replace(/,/, '.')));
}
function formatEthers(session:UDICESession, value:bigint){
    return (parseFloat(session.fromWei(value))).toLocaleString(undefined, {maximumSignificantDigits: 10, useGrouping: true})
}
function createUDICEInputValue(session:UDICESession, amount:string, multiplier:string, feeOnTheHouse:boolean):UDICEInputValue{
    let _amount = parseEthers(session, amount);
    let _multiplier = parseEthers(session, multiplier);
    if(ReferralManager.isReferred()){
        console.log("Applying referrer on multiplier: "+ReferralManager.getReferrer())
        return {amount:_amount, multiplier: _multiplier + ReferralManager.getReferrer(), feeOnTheHouse};
   }
    return {amount:_amount, multiplier: _multiplier, feeOnTheHouse};
}

export class UDICEPlayInputMultiplierPresets extends HTMLElement{
    static dummy(){
    }
    constructor(){
        super();
        this.classList.add("d-inline-block");
        this.style.fontSize = "x-small";
        this.innerHTML = `
        (<a href="#" id="play-input-lucky-draw">Lucky Draw</a> -
            <a href="#" id="play-input-coin-flip">Coin Flip</a> - 
            <a href="#" id="play-input-dice-throw">Dice Throw</a> - 
            <a href="#" id="play-input-lottery">Lottery</a>)
        `;
        //this.querySelector("#play-input-coin-flip").addEventListener("click", e=>this.multiplier(e, 1.8800));
        //this.querySelector("#play-input-dice-throw").addEventListener("click", e=>this.multiplier(e, 5.6398));
        //this.querySelector("#play-input-lottery").addEventListener("click", e=>this.multiplier(e, 60_000));
        this.querySelector("#play-input-lucky-draw").addEventListener("click", e=>this.multiplier(e, 1.05));
        this.querySelector("#play-input-coin-flip").addEventListener("click", e=>this.multiplier(e, 2));
        this.querySelector("#play-input-dice-throw").addEventListener("click", e=>this.multiplier(e, 6));
        this.querySelector("#play-input-lottery").addEventListener("click", e=>this.multiplier(e, 60_000));
    }
    multiplier(e: Event, value: number): void {
        e.preventDefault();
        let input: IUDICEPlayInput = this.findPlayInput();
        if(input==null) return;
        let v = input.value;
        //input.value = {amount: v.amount, multiplier: BigInt(value)*(BigInt(10)**BigInt(18)), feeOnTheHouse:v.feeOnTheHouse};
        let multiplier = new BigNumber(value).multipliedBy(new BigNumber(10).pow(18)).integerValue(BigNumber.ROUND_HALF_EVEN).toString(10);
        input.value = {amount: v.amount, multiplier: BigInt(multiplier), feeOnTheHouse:v.feeOnTheHouse};
        
    }
    findPlayInput(): IUDICEPlayInput {
        let p = this.parentElement;
        while(p!=null){
            if(p.nodeName.toLocaleLowerCase().startsWith("udice-application-play-input")){
                return p as IUDICEPlayInput;
            }
            p = p.parentElement;
        }
        return null;
    }
}
customElements.define("udice-application-play-input-presets", UDICEPlayInputMultiplierPresets);
//new UDICEPlayInputMultiplierPresets();
UDICEPlayInputMultiplierPresets.dummy();

export class UDICEPlayInput extends HTMLElement implements IUDICEPlayInput{
    
    private _amountElement: HTMLInputElement;
    private _factorElement: HTMLInputElement;
    private _chanceElement: HTMLInputElement;
    private _betElement: HTMLInputElement;
    private _currencyCode: any;
    private _session: UDICESession;
    private _feeOnTheHouseElement: HTMLInputElement;
    
    constructor(){
        super();
        this.innerHTML = `

            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <label style="white-space: nowrap;">Spend <span id="play-input-amount-in-currency"></span></label>
                    <div class="input-group">
                        <input type="text" id="play-input-amount" class="form-control fs-5 fw-bold text-end" require>
                        <span class="input-group-text play-input-currency-code-placeholder">BNB</span>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <label>Multiplier</label>
                    <div class="input-group">
                        <input type="number" id="play-input-multiplier-factor-input" min="1" max="65536" class="form-control fs-5 fw-bold text-end">
                        <span class="input-group-text">✕</span>
                    </div>
                </div>
                <div class="d-col-md-6 col-sm-6 d-none d-sm-block ">
                    <label style="white-space: nowrap;">Chance</label>
                    <div class="input-group">
                        <input type="number" id="play-input-chance" min="0" max="100" class="form-control fs-5 fw-bold text-end">
                        <span class="input-group-text">%</span>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 d-none d-md-block ">
                    <label style="white-space: nowrap;">Roll Under ...</label>
                    <div class="input-group">
                        <input type="number" id="play-input-bet" min="1" max="65536" class="form-control fs-5 fw-bold text-end">
                        <span class="input-group-text">≤</span>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="form-check form-switch col-md-6">
                        <input class="form-check-input" type="checkbox" id="feeOnTheHouseCheckBox" checked>
                        <label class="form-check-label" for="feeOnTheHouseCheckBox">Fee on the house</label>
                    </div>
                    <div class="col-md-6">
                        <udice-application-play-input-presets></udice-application-play-input-presets>
                    </div>
                </div>
            </div>
            
        `;
        this._amountElement = this.querySelector("#play-input-amount") as HTMLInputElement;
        this._factorElement = this.querySelector("#play-input-multiplier-factor-input") as HTMLInputElement;
        this._chanceElement = this.querySelector("#play-input-chance") as HTMLInputElement;
        this._betElement = this.querySelector("#play-input-bet") as HTMLInputElement;
        this._currencyCode = this.querySelector(".play-input-currency-code-placeholder");
        this._feeOnTheHouseElement = this.querySelector("#feeOnTheHouseCheckBox") as HTMLInputElement;

        this._amountElement.addEventListener("input", e=>{
            //this.fireChangeEvent();
            this.updateChanceFromMultiplier().then(()=>{this.fireChangeEvent();}).catch((reason)=>console.log(reason));
        });
        this._factorElement.addEventListener("input", e=>{
            this.updateChanceFromMultiplier().then(()=>{this.fireChangeEvent();}).catch((reason)=>console.log(reason));
        });
        if(this._chanceElement!=null)
        this._chanceElement.addEventListener("input", e=>{
            this.updateMultiplierFromChance().then(()=>{this.fireChangeEvent();}).catch((reason)=>console.log(reason));
        });
        if(this._betElement!=null)
        this._betElement.addEventListener("input", e=>{
            this.updateMultiplierFromBet().then(()=>{this.fireChangeEvent();}).catch((reason)=>console.log(reason));
        })
        this._feeOnTheHouseElement.addEventListener("input", e=>{
            //this.fireChangeEvent();
            this.updateChanceFromMultiplier().then(()=>{this.fireChangeEvent();}).catch((reason)=>console.log(reason));
            
            this.promptAdjustSpendAfterFeeOnTheHouseChange();
        });
    }

    get session():UDICESession{
        return this._session;
    }
    set session(newValue:UDICESession){
        this._session = newValue;

        let launched = this._session!=null && this._session.contractInfo.launchTime.getTime()<Date.now();

        this._amountElement.disabled = newValue==null || !launched;
        this._factorElement.disabled = newValue==null || !launched;
        if(this._chanceElement!=null)
            this._chanceElement.disabled = newValue==null || !launched;
        if(this._betElement!=null)
            this._betElement.disabled = newValue==null || !launched;
        this._feeOnTheHouseElement.disabled = newValue==null || !launched;

        if(newValue==null){
            this._currencyCode.innerHTML = "";
        }else{
            this._currencyCode.innerHTML = this._session.getCoinsSymbol();
        }
    }
    get value(): UDICEInputValue{
        let result = createUDICEInputValue(this._session, this._amountElement.value, this._factorElement.value, this._feeOnTheHouseElement.checked);
        this._amountElement.checkValidity
        return result;
    }
    set value(newValue: UDICEInputValue){
        this._amountElement.value = this._session.web3.utils.fromWei(newValue.amount, "ether"); //formatEthers(this._session, newValue.amount);
        this._factorElement.value = this._session.web3.utils.fromWei(newValue.multiplier, "ether"); //formatEthers(this._session, newValue.multiplier);
        this.updateChanceFromMultiplier().then(()=>{this.fireChangeEvent();}).catch((reason)=>console.log(reason));
        this._feeOnTheHouseElement.checked = newValue.feeOnTheHouse;
        this.updateSpendEstimateDisplay();
    }

    private fireChangeEvent(){
        this.updateSpendEstimateDisplay();
        let event = new CustomEvent("change", {detail: this.value});
        this.dispatchEvent(event)
    }

    async updateMultiplierFromChance() : Promise<any> {
        if(this._chanceElement==null) return null;
        let v = this.value;
        let chance: number = parseFloat(this._chanceElement.value)/100;
        let estimates = await this._session.estimatePlayByChance({amount: v.amount, chanceRate: chance, feeOnTheHouse: v.feeOnTheHouse});
        const multiplier = this._session.web3.utils.fromWei(estimates.multiplier, "ether");
        this._factorElement.value = parseFloat(multiplier).toPrecision(5);
        if(this._betElement!=null)
            this._betElement.value = estimates.bet.toString(10);
        return estimates;
    }

    async updateChanceFromMultiplier() : Promise<any>{
        let v = this.value;
        let estimates = await this._session.estimatePlayByMultiplier({amount: v.amount, multiplier: v.multiplier, feeOnTheHouse: v.feeOnTheHouse});
        if(this._chanceElement!=null)
            this._chanceElement.value = (estimates.won.probability*100).toLocaleString(undefined, {maximumFractionDigits: 3, maximumSignificantDigits: 4});
        if(this._betElement!=null)
            this._betElement.value = estimates.bet.toString(10);
        return estimates;
    }

    async updateMultiplierFromBet(): Promise<any>{
        if(this._betElement==null) return;
        let v = this.value;
        let bet: number = this._betElement.valueAsNumber;
        let estimates = await this._session.estimatePlayByBet({amount: v.amount, bet: bet, feeOnTheHouse: v.feeOnTheHouse});
        const multiplier = this._session.web3.utils.fromWei(estimates.multiplier, "ether");
        this._factorElement.value = parseFloat(multiplier).toPrecision(5);
        this._chanceElement.value = (estimates.won.probability*100).toLocaleString(undefined, {maximumFractionDigits: 3, maximumSignificantDigits: 4});
        return estimates;
    }

    async promptAdjustSpendAfterFeeOnTheHouseChange(){
        let configuration = await this._session.getConfiguration();
        
        let currentValue = this.value;
        let adjustedValue : UDICEInputValue;
        if(this._feeOnTheHouseElement.checked){
            adjustedValue = {amount: currentValue.amount - configuration.projectFee, multiplier: currentValue.multiplier, feeOnTheHouse: currentValue.feeOnTheHouse}; 
        }else{
            adjustedValue = {amount: currentValue.amount + configuration.projectFee, multiplier: currentValue.multiplier, feeOnTheHouse: currentValue.feeOnTheHouse};
        }
        this.value = adjustedValue;
    }

    async updateSpendEstimateDisplay(){
        let e = this.querySelector("#play-input-amount-in-currency");
        if(this._session!=null && this._session.contractInfo.support!=null){
            let support = this._session.contractInfo.support(this._session.web3);
            let valuer = await support.getValuer();
            if(valuer !=null){
                let v = valuer.coinsToFiat(this.value.amount).toString;
                e.innerHTML = ` (${v})`;
                return;
            }
        }
        e.innerHTML = "";
    }
}
customElements.define('udice-application-play-input', UDICEPlayInput);
new UDICEPlayInput();

export class UDICEPlayInputLegacy extends HTMLElement implements IUDICEPlayInput{

    amountTextBox: HTMLInputElement;
    feeOnTheHouseCheckBox: HTMLInputElement;
    multiplierRange: HTMLInputElement;
    multiplierNumber: HTMLInputElement;
    private _session: UDICESession;
 
    constructor(){
        super();
        this.innerHTML = `
            <style>
                .multiplier-range-input{
                    width: 100%;
                    border: 1px solid darkgray;
                    border-radius: 0.375rem 0.375rem 0px 0px;
                    appearance: none;
                    height: 8px;
                }
                .multiplier-range-input::-webkit-slider-thumb {
                    -webkit-appearance : none;
                    background : red;
                    height : 20px;
                    width : 20px; 
                    border-radius: 16px; border: 1px solid green; box-shadow: 0px 0px 1px 0px;
                  }
                .multiplier-text-input{
                    /*border-top: 0px !important;*/
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                }
            </style>
            <div class="form-group p-3 shadow rounded border">
                <label>Spend</label>
                <div class="input-group">
                    <input type="number" class="form-control" placeholder="Spend" id="amountTextBox" value="" aria-label="Spend input" aria-describedby="addon-switch">
                    <div class="input-group-text">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="feeOnTheHouseCheckBox" checked>
                            <label class="form-check-label" for="feeOnTheHouseCheckBox">Fee on the house</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group p-3 shadow mt-2 rounded border">
              <label>Multiplier</label>
              <input type="range" min="1" max="1000" step="1" id="multiplierRange" class="multiplier-range-input" value="2">
              <input type="number" min="1" max="65000" step="1" class="form-control multiplier-text-input" placeholder="Multiplier" id="multiplierNumber" value="2">

              <!--
              <input type="checkbox" id="multiplierBoostCheckBox"> Use tokens to boost multiplier
              -->
            </div>        
        `;

        this.amountTextBox = this.querySelector("#amountTextBox") as HTMLInputElement;
        this.feeOnTheHouseCheckBox = this.querySelector("#feeOnTheHouseCheckBox") as HTMLInputElement;
        this.multiplierRange = this.querySelector("#multiplierRange") as HTMLInputElement;
        styleRangeThumb(this.multiplierRange);
        this.multiplierNumber = this.querySelector("#multiplierNumber") as HTMLInputElement;
        
        this.amountTextBox.addEventListener("input", () => {
            this.fireChangeEvent();
        });
        this.feeOnTheHouseCheckBox.addEventListener("input", () => {
            this.fireChangeEvent();
        });
        this.multiplierRange.addEventListener("input", () => {
            this.multiplierNumber.value = this.multiplierRange.value;
            this.fireChangeEvent();
        });
        this.multiplierNumber.addEventListener("input", () => {
            this.multiplierRange.value = this.multiplierNumber.value;
            updateThumbStyle(this.multiplierRange);
            this.fireChangeEvent();
        });
    }
    fireChangeEvent() {
        let event = new CustomEvent("change", {detail: this.value });
        this.dispatchEvent(event);
    }
    get session(): UDICESession {
        return this._session;
    }
    set session(newValue: UDICESession) {
        this._session = newValue;

        let launched = this._session!=null && this._session.contractInfo.launchTime.getTime()<Date.now();

        this.amountTextBox.disabled = newValue==null || !launched;
        this.multiplierNumber.disabled = newValue==null || !launched;
        this.multiplierRange.disabled = newValue==null || !launched;
        this.feeOnTheHouseCheckBox.disabled = newValue==null || !launched;

    }
    get value(): UDICEInputValue {
        return createUDICEInputValue(this.session, this.amountTextBox.value, this.multiplierNumber.value, this.feeOnTheHouseCheckBox.checked);
    }
    set value(newValue: UDICEInputValue) {
        this.amountTextBox.value = formatEthers(this.session, newValue.amount);
        this.multiplierNumber.value = formatEthers(this.session, newValue.multiplier);
        this.feeOnTheHouseCheckBox.checked = newValue.feeOnTheHouse;
    }
}
customElements.define('udice-application-play-input-legacy', UDICEPlayInputLegacy);
new UDICEPlayInputLegacy();

export class UDICEPlayerEstimatesOutput extends HTMLElement{

    _estimatesProjectFeeElement: HTMLElement;
    _estimatesWagerElement: HTMLElement;
    _estimatesHouseEdgeElement: HTMLElement;
    _estimatesWinChanceElement: HTMLElement;
    _estimatesMultiplierBoostTokens: HTMLElement;
    _estimatesWinPayoutElement: HTMLElement;
    _estimatesLosePayoutElement: HTMLElement;
    _estimatesDonationPayoutElement: HTMLElement;
    private _session: UDICESession;

    constructor(){
        super();
        this.innerHTML = `
            <div class="form-group mt-3 container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 badge bg-dark col overflow-auto" title="The bet amount">
                        wager: <span id="wager"></span>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 badge bg-dark col overflow-auto" title="Fee paid to cover project costs">
                        project fee: <span id="projectFee"></span>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 badge bg-dark col overflow-auto" title="Statistical advantage of the house. All house earnings go towards growing the jackpot">
                        house edge: <span id="houseEdge"></span>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 badge bg-dark col overflow-auto" title="Likelyhood of winning">
                        chance: <span id="winChance"></span>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 badge bg-warning col overflow-auto" title="Price for boosting multiplier above the free multiplier limit. The tokens are burnt from your balance">
                        burn: <span id="multiplierBoostTokens"></span>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 badge bg-success col overflow-auto" title="Payout if the bet is won">
                        win payout: <span id="winPayout"></span>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 badge bg-danger col overflow-auto" title="Payout if the bet is lost">
                        lose payout: <span id="losePayout"></span>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 badge bg-info col overflow-auto" title="Payout if the wager is donated to the jackpot. This happens when the jackpot is below the minimum allowed jackpot">
                        donation: <span id="donationPayout"></span>
                    </div>
                </div>
            </div>
        `;

        this._estimatesProjectFeeElement = this.querySelector("#projectFee") as HTMLElement;
        this._estimatesWagerElement = this.querySelector("#wager") as HTMLElement;
        this._estimatesHouseEdgeElement = this.querySelector("#houseEdge") as HTMLElement;
        this._estimatesWinChanceElement = this.querySelector("#winChance") as HTMLElement;
        this._estimatesMultiplierBoostTokens = this.querySelector("#multiplierBoostTokens") as HTMLElement;
        this._estimatesWinPayoutElement = this.querySelector("#winPayout") as HTMLElement;
        this._estimatesLosePayoutElement = this.querySelector("#losePayout") as HTMLElement;
        this._estimatesDonationPayoutElement = this.querySelector("#donationPayout") as HTMLElement;

        this.installToolTips();
    }

    async installToolTips(){
        this.querySelectorAll(".badge").forEach(badge=>{
            //let existing = Tooltip.getInstance(badge);
            //if(existing!=null) existing.dispose();
            //let tooltip = Tooltip.getOrCreateInstance(badge, {html: true});
            let content = badge.getAttribute("title");
            let title = badge.firstChild.textContent.trim();
            //const popover:Popover = Popover.getOrCreateInstance(badge, {html: true, title: title, content: content, trigger: 'click hover focus'});
            
            badge.addEventListener("show.bs.popover", e=>{
                this.querySelectorAll(".badge").forEach(badge2=>{
                    if(badge!=badge2){
                        let i = Popover.getInstance(badge2);
                        if(i!=null){
                            i.hide();
                        }
                    }
                });
            });
            badge.addEventListener("show.bs.popover", e=>{
                if(this._session==null){
                    e.preventDefault();
                }
            });
            (badge as HTMLElement).style.cursor = "pointer";
            badge.addEventListener("click", e=>{
                var html = badge.outerHTML;
                badge.classList.forEach(c=>{
                    if(c.indexOf("col-")!=-1){
                        html = html.replaceAll(c, "");
                    }
                })
                UDICEDialog.show({
                    titleHtml: title,
                    bodyHtml: "<div style='width: 100%'>" + content +"<hr />"+html+"</div>", 
                    fullscreen: false
                });
                //alert(badge.outerHTML);
            });
            //if(title!=null){
                //badge.setAttribute("data-bs-toggle", "tooltip");
                //badge.setAttribute("data-bs-title", title);
                //badge.setAttribute("data-bs-html", "true");
                
            //}
            //tooltip.
        });
    }

    async setEstimates(session: UDICESession, estimates:EstimatePlayResult){
        this._session = session;
        if(session==null || estimates==null){
           this.querySelectorAll("span").forEach(span=>{
            span.innerHTML = "";
           })
            return;
        }
        let coinSymbol = session.getCoinsSymbol();
        //let tokenSymbol = "UDUH";
        let tokenSymbol = await session.getTokensSymbol();

        let currencyConverter: IUdiceValuer;
        if(this._session.contractInfo.support!=null){
            let support = this._session.contractInfo.support(this._session.web3);
            currencyConverter = await support.getValuer();   
        }else{
            null;
        }

        this._estimatesProjectFeeElement.innerHTML = session.fromWei(estimates.prepaidProjectFee);
        this._estimatesWagerElement.innerHTML = session.fromWei(estimates.wager);
        if(currencyConverter!=null){
            let wager = currencyConverter.coinsToFiat(estimates.wager);
            if(wager.toString!=null){
                this._estimatesWagerElement.innerHTML = session.fromWei(estimates.wager) + " (" + wager.toString +")";
            }
        }
        let houseEdgePercent:string; // = (((estimates.houseEdge * BigInt(100000))/estimates.wager).toString()/1000)

        //.toLocaleString(undefined, { maximumSignificantDigits: 4});
        if (estimates.wager != BigInt(0)) {
            //houseEdgePercent = (((estimates.houseEdge * BigInt(100000)) / estimates.wager).toString() / 1000).toLocaleString(undefined, { maximumSignificantDigits: 4 });
            let houseEdgePer1000:bigint = (estimates.houseEdge * BigInt(100000))/BigInt(estimates.wager);
            houseEdgePercent = (houseEdgePer1000/BigInt(1000)).toLocaleString(undefined, { maximumSignificantDigits: 4 });
        } else {
            houseEdgePercent = "-";
        }
        this._estimatesHouseEdgeElement.innerHTML = session.fromWei(estimates.houseEdge) + " (" + houseEdgePercent + "%)";
        this._estimatesWinChanceElement.innerHTML = ((parseFloat(estimates.bet.toString()) * 100) / 65536).toLocaleString(undefined, { maximumSignificantDigits: 4 }) + "%";

        let formattedOdds = estimates.odds.toLocaleString(undefined, {maximumFractionDigits:2});
        this._estimatesWinChanceElement.innerHTML = this._estimatesWinChanceElement.innerHTML + (estimates.odds==0?"":" ("+(formattedOdds+"/1"+")"));

        let switchClasses = function (element: HTMLElement|null, fromClassName:string, toClassName:string){
            if(element!=null){
                element.classList.remove(fromClassName);
                element.classList.add(toClassName);
            } 
        }

        if (estimates.multiplierBoostTokens > BigInt(0)) {
            this._estimatesMultiplierBoostTokens.innerHTML = session.fromWei(estimates.multiplierBoostTokens) + ` ${tokenSymbol}`;
            switchClasses(this._estimatesMultiplierBoostTokens.parentElement, "bg-secondary", "bg-warning");
        } else {
            this._estimatesMultiplierBoostTokens.innerHTML = "0";
            switchClasses(this._estimatesMultiplierBoostTokens.parentElement, "bg-warning", "bg-secondary");
        }
        if (estimates.won) {
            let payoutCoins = estimates.won.payoutCoins + estimates.won.refundCoins;

            let winParts = [];
            if(payoutCoins > BigInt(0)){
                winParts.push(session.fromWei(payoutCoins) + ` ${coinSymbol}`);
                if(currencyConverter!=null){
                    let v = currencyConverter.coinsToFiat(payoutCoins).toString;
                    if(v!=null) winParts[winParts.length-1] = winParts[winParts.length-1] + ` (${v})`; 
                }
            }
            if(estimates.won.payoutTokens > BigInt(0)) winParts.push(session.fromWei(estimates.won.payoutTokens) + ` ${tokenSymbol}`);
            this._estimatesWinPayoutElement.innerHTML = winParts.join(" + ");
            if(estimates.won.probability > 0){
                switchClasses(this._estimatesWinPayoutElement.parentElement, "bg-secondary", "bg-success");
            }else{
                switchClasses(this._estimatesWinPayoutElement.parentElement, "bg-success", "bg-secondary");
            }
        } else {
            this._estimatesWinPayoutElement.innerHTML = "0";
            switchClasses(this._estimatesWinPayoutElement.parentElement, "bg-success", "bg-secondary");
        }
        if (estimates.lost) {
            this._estimatesLosePayoutElement.innerHTML = session.fromWei(estimates.lost.payoutTokens) + ` ${tokenSymbol}`;
            if(estimates.lost.probability > 0){
                switchClasses(this._estimatesLosePayoutElement.parentElement, "bg-secondary", "bg-danger");
            }else{
                switchClasses(this._estimatesLosePayoutElement.parentElement, "bg-danger", "bg-secondary");
            }
        } else {
            this._estimatesLosePayoutElement.innerHTML = "0";
            switchClasses(this._estimatesLosePayoutElement.parentElement, "bg-danger", "bg-secondary");
        }
        if (estimates.donated) {
            //estimates.donated.donation
            this._estimatesDonationPayoutElement.innerHTML = session.fromWei(estimates.donated.refundCoins) + ` ${coinSymbol} + ` +
                session.fromWei(estimates.donated.payoutTokens) + ` ${tokenSymbol}`;
            if(estimates.donated.probability > 0){
                switchClasses(this._estimatesDonationPayoutElement.parentElement, "bg-secondary", "bg-info");
            }else{
                switchClasses(this._estimatesDonationPayoutElement.parentElement, "bg-info", "bg-secondary");
            }
        } else {
            this._estimatesDonationPayoutElement.innerHTML = "0";
            switchClasses(this._estimatesDonationPayoutElement.parentElement, "bg-info", "bg-secondary");
        }
        function showIfProbable(element: HTMLElement, probability: number){
            if(probability>0){
                element.classList.remove("d-none");
            }else{
                element.classList.add("d-none");
            }
        }
        showIfProbable(this._estimatesMultiplierBoostTokens.parentElement, estimates.multiplierBoostTokens > BigInt(0)?1:0);
        showIfProbable(this._estimatesWinPayoutElement.parentElement, estimates.won?.probability);
        showIfProbable(this._estimatesLosePayoutElement.parentElement, estimates.lost?.probability);
        showIfProbable(this._estimatesDonationPayoutElement.parentElement, estimates.donated?.probability);

    }
}
customElements.define("udice-application-estimates-output", UDICEPlayerEstimatesOutput);
new UDICEPlayerEstimatesOutput();

