import { Address, Bytes, HexString, Numbers, ValueTypes } from 'web3-types';
export { Contract, ContractDeploySend, ContractMethodSend } from 'web3-eth-contract';
import { Contract, ContractDeploySend, ContractMethodSend } from 'web3-eth-contract';
import { Web3EthInterface } from 'web3';
import {ContractAbi} from "web3-types";
import {
    uint,
    ISubmission, ISubmissionOutcome, GetConfigurationResult, GetStateResult, 
    OutcomeEventReturnValues, RefundResult, CreditIssuedEventReturnValues, 
    SubmittedEvent, OutcomeEvent,
    CreditClaimedEventReturnValues,
    SubmittedEventReturnValues,
    UDICEContractMethods,
    GetPlayerInfoResult
    } from './udice-connect-contract.ts';

import { Web3 } from 'web3';
//import Eth from 'web3-eth';
import * as Config from './udice.config.ts';
import {UDICEProvider} from './udice-connect-providers.ts';

export const SUBMISSION_STATUS_RESOLVING = 1;
export const SUBMISSION_STATUS_WON = 2;
export const SUBMISSION_STATUS_LOST = 4;
//export const SUBMISSION_STATUS_DONATED = 8;
//export const SUBMISSION_STATUS_REFUNDED = 16;
export const SUBMISSION_STATUS_REFUNDED = 8;
export const MAXIMUM_BET = 65_536;
export const FREE_MULTIPLIER_LIMIT = BigInt(1000) * (BigInt(10)**BigInt(18));
const ETH:bigint = BigInt(10) ** BigInt(18);

interface EstimatePlayResultDonated{
    probability: number;
    donation: bigint,
    payoutTokens: bigint,
    refundCoins: bigint
}

class EstimatePlayResultWon{
    probability: number;
    payoutCoins: bigint; 
    refundCoins: bigint; 
    payoutTokens: bigint;
}
class EstimatePlayResultLost{
    probability: number;
    refundCoins : bigint;
    payoutTokens : bigint
}
export class EstimatePlayResult{
    "wager":bigint;
    "multiplier": bigint; 
    "prepaidProjectFee":bigint; 
    "multiplierBoostTokens": bigint;
    "bet": number;
    "houseEdge": bigint;
    "status": number;
    "won"?: EstimatePlayResultWon;
    "lost"?: EstimatePlayResultLost;
    "donated"?: EstimatePlayResultDonated;
    "odds": number
}

export class UDICESession{

    static createContract(web3: Web3, chain: Config.UdiceContractInfo, account:string){
        return new web3.eth.Contract(chain.contractAbi, chain.contractAddress, {
            from: account,
            //gasPrice: '20000000000', // default gas price in wei, 20 gwei in this case
            //gasPrice: gasPrice.toString(10),
            //gas: '1000000',
            //handleRevert: true
        });
    }
    static createTransactionContract(udiceProvider: UDICEProvider, chain: Config.UdiceContractInfo, account:string){
        return this.createContract(udiceProvider.web3, chain, account);
    }

    static createStreamingContract(udiceProvider: UDICEProvider, chain: Config.UdiceContractInfo, account:string){
        let wsUrls = chain.chain.rpc.filter(item=>item.startsWith("ws"));
        if(wsUrls.length==0){
            return this.createTransactionContract(udiceProvider, chain, account);
        }
        let webSocketUrl = wsUrls[0];
        const web3 = new Web3(new Web3.providers.WebsocketProvider(webSocketUrl));
        return this.createContract(web3, chain, account);
    }

    udiceProvider: UDICEProvider;
    web3: Web3;
    chainId: number;
    chainInfo: Config.ChainInfo;
    readonly contractInfo: Config.UdiceContractInfo;
    account: string;
    contract: Contract<ContractAbi>;
    streamContract: Contract<ContractAbi>;
    _subscriptions: Set<{unsubscribe: () => void;}>;
    //_submissions: UDICESessionSubmissions;
    //_mysubmissions: UDICESessionSubmissions;
    receipt: any;
    state: GetStateResult | Promise<GetStateResult>;
    configuration: GetConfigurationResult | Promise<GetConfigurationResult>;
    _configurationChangedEventListener: any;
    transferEventsSubscription: any;
    private _blocksTimestamps: Map<uint, bigint> = new Map();
    private _submissions2: UDICESessionSubmissionsRegistryImpl;
    _tokensSysmbol: string;

    constructor(udiceProvider: UDICEProvider, contractInfo: Config.UdiceContractInfo, account: string){
        this.udiceProvider = udiceProvider;
        this.web3 = udiceProvider.web3;
        this.contractInfo = contractInfo;
        this.chainId = contractInfo.chain.chainId;
        this.chainInfo = contractInfo.chain;
        this.account = account;
        //this.contract = contract;
        this.contract = UDICESession.createTransactionContract(udiceProvider, contractInfo, account);
        this.streamContract = UDICESession.createStreamingContract(udiceProvider, contractInfo, account);
        this._subscriptions = new Set();
        //this._submissions = new UDICESessionSubmissions(this);
        //this._mysubmissions = new UDICESessionSubmissions(this, {"player": account});
        this._submissions2 = new UDICESessionSubmissionsRegistryImpl(this);

    }
	public contractUrl(){
		let contractInfo = this.contractInfo;
		if(contractInfo.chain.explorers==null || contractInfo.chain.explorers.length==0) return "";
		return contractInfo.chain.explorers[0].url+"/address/"+contractInfo.contractAddress;
	}
    fromWei(value : Numbers):string{
        //return this.web3.utils.fromWei(value, "ether");
        let result = parseFloat(this.web3.utils.fromWei(value, "ether")).toLocaleString(navigator.language, { maximumSignificantDigits: 8});
        return result;
    }
    toWei(value : Numbers){
        return this.web3.utils.toWei(value, "ether");
    }
    get ether():bigint{
        return ETH;
    }

    async coinsToFiat(coinsValue: bigint): Promise<{amount: bigint; currencyCode: string; toString: string; format: ()=>string}>{
        let support = this.contractInfo.support(this.web3);
        let valuer = await support.getValuer();
        if(valuer !=null){
            return valuer.coinsToFiat(coinsValue);   
        }
        return {amount: null, currencyCode: null, toString: null, format: ()=>null};
    }
    async coinsToFiatFormatted(coinsValue: bigint){
        return (await this.coinsToFiat(coinsValue)).format();
    }

    async epochize(submissionId : bigint, amount : bigint): Promise<bigint>{
        let configuration = await this.getConfiguration();
        let epoch = (submissionId - BigInt(1)) / BigInt(configuration.submissionsPerEpoch);

        return amount >> epoch;
    }

    computeRewardHouseEdge(wager: bigint, prepaidProjectFee: bigint, configuration: GetConfigurationResult): bigint{
        let feeOnTheHouse: bigint = configuration.projectFee;
        let houseEdge: bigint = feeOnTheHouse + configuration.houseEdgeFixed + ((wager * configuration.houseEdgeRate) / (this.ether));
        return houseEdge;
    }
    computeHouseEdge(wager: bigint, prepaidProjectFee: bigint, configuration: GetConfigurationResult): bigint{
        let _projectFee: bigint = configuration.projectFee;
        let _houseEdgeFixed: bigint = configuration.houseEdgeFixed;
        let _houseEdgeRate: bigint = configuration.houseEdgeRate;

        var feeOnTheHouse: bigint;
        if(prepaidProjectFee < _projectFee){
            feeOnTheHouse = _projectFee - prepaidProjectFee;
        }else{
            feeOnTheHouse = BigInt(0);
        }
        let houseEdge = feeOnTheHouse + _houseEdgeFixed + ((wager * _houseEdgeRate) / (this.ether));
        return houseEdge;
    }

    public async getBlockTimestamp(blockNumber : uint) : Promise<bigint>{
        if(!this._blocksTimestamps.get(blockNumber)!=null){
            let block = await this.web3.eth.getBlock(blockNumber);
            let timestamp : bigint = block.timestamp;
            this._blocksTimestamps.set(blockNumber, timestamp);
        }
        return this._blocksTimestamps.get(blockNumber);
    }

    computeBet(wager: bigint, prepaidProjectFee:bigint, multiplier:bigint, configuration: GetConfigurationResult): number{
        let houseEdge = this.computeHouseEdge(wager, prepaidProjectFee, configuration);
        if(wager <= houseEdge){
            return 0;
        }
        if(multiplier > BigInt(65536) * this.ether){
            return 0;
        }
        if(multiplier==BigInt(0)){
            return MAXIMUM_BET;
        }
        let wagerLessHouseEdge = wager - houseEdge;
        let bet:bigint = (wagerLessHouseEdge * BigInt(MAXIMUM_BET) * (this.ether) ) / (wager * multiplier);
        if(bet > MAXIMUM_BET){
            return MAXIMUM_BET;
        }
        return Number(bet);
    }

    computMultiplierFromBet(wager: bigint, prepaidProjectFee:bigint, bet:number, configuration: GetConfigurationResult ): bigint{
        let houseEdge = this.computeHouseEdge(wager, prepaidProjectFee, configuration);
        if(wager <= houseEdge){
            return BigInt(0);
        }
        bet = Math.round(bet);
        if(bet > 65536){
            return BigInt(0);
        }
        if(bet==0){
            return BigInt(MAXIMUM_BET) * this.ether;
        }
        let wagerLessHouseEdge = wager - houseEdge;
        let multiplier:bigint = (wagerLessHouseEdge * BigInt(MAXIMUM_BET) * (this.ether) ) / (wager * BigInt(bet));
        return multiplier;
    }

    async computeMultiplierBoostTokens(wager : bigint, multiplier : bigint) : Promise<bigint>{
        let state = await this.getState();
        if(multiplier <= FREE_MULTIPLIER_LIMIT) return BigInt(0);
        //if(state.totalSupply==BigInt(0)) return BigInt(0);
        if(state.rewardPool==BigInt(0)) return state.totalSupply;
        return (wager * (multiplier - FREE_MULTIPLIER_LIMIT) * state.totalSupply) / (state.rewardPool * BigInt(65_536) * this.ether);
    }

    async computeMinimumRecommendedWager(){
        let state = await this.getState();
        let config = await this.getConfiguration();
        let result = ((config.houseEdgeFixed + config.projectFee) * this.ether)/config.houseEdgeRate;
        return result;
    }

    get methods() : UDICEContractMethods{
        return this.contract.methods as unknown as UDICEContractMethods;
    }

    async play(amount : bigint, multiplier : bigint, feeOnTheHouse:boolean) : Promise<UDICESubmission>{

        const _amount = amount;
        const _multiplier = multiplier;
        let prepaidProjectFee : bigint;
        if(feeOnTheHouse){
            prepaidProjectFee = BigInt(0);
        }else{
            let config = await this.getConfiguration();
            prepaidProjectFee = config.projectFee;
        }

        let gasPrice = await this.web3.eth.getGasPrice();

        //let method = this.contract.methods.playWithFee(_multiplier, prepaidProjectFee);
        let method = this.methods.playWithFee(_multiplier, prepaidProjectFee);
        /*
        let gasEstimation: bigint = await method.estimateGas({"from": this.account,
            //"value": _amount.toString(10),
        });
        //console.log(gasEstimation);
        let gasEstimation2: bigint = gasEstimation + BigInt(gasEstimation/BigInt(5));
        //let gas = gasEstimation2;
        */

       let gas = this.contractInfo.playGas;

        const methodSendEventEmitter = method.send({
            "from": this.account,
            "value": _amount.toString(10),
            //"gasPrice": this.web3.utils.toHex(gasPrice),
            //"gas": "4000000",
            "gas": gas, "gasPrice": gasPrice.toString(10)
        });

        let receipt = await methodSendEventEmitter;
        let submitted: SubmittedEvent = receipt.events.Submitted as any as SubmittedEvent;
        return this._submissions2._onNewSubmittedEvent(submitted);
    }

    async refund(submissionId : bigint) : Promise<RefundResult>{
        const emitter = this.contract.methods.refund(submissionId).send({"from": this.account});

        let receipt = await emitter;
        let outcomeEvent: OutcomeEventReturnValues = receipt.events.Outcome.returnValues as unknown as OutcomeEventReturnValues;
        let creditIssuedEvent : CreditIssuedEventReturnValues;
        if(receipt.events.CreditIssued!=null){
            creditIssuedEvent = receipt.events.CreditIssued.returnValues as unknown as CreditIssuedEventReturnValues;
        }else{
            creditIssuedEvent = null;
        }
        this.state = null;
        return {outcomeEvent: outcomeEvent, creditIssuedEvent: creditIssuedEvent};
    }

    async claimCredit() : Promise<CreditClaimedEventReturnValues>{
        const emitter = this.contract.methods.reclaimCredit().send({"from": this.account});
        return new Promise(async (resolve, reject)=>{
            try{
                let receipt = await emitter;
                let creditClaimed : CreditClaimedEventReturnValues = receipt.events.CreditClaimed.returnValues as unknown as CreditClaimedEventReturnValues;
                resolve(creditClaimed);
            }catch(error){
                reject(error);
            }
        });
    }

    async redeem(tokensAmount:bigint){
        const emitter = this.contract.methods.redeem(tokensAmount).send({"from": this.account});
        return new Promise(async (resolve, reject)=>{
            try{
                let receipt = await emitter;
                resolve({
                    'player': receipt.events.Redeemed.returnValues.player,
                    'burntTokens': receipt.events.Redeemed.returnValues.burntTokens,
                    'payoutCoins': receipt.events.Redeemed.returnValues.payoutCoins,
                });
                this.state = null;
            }catch(error){
                reject(error);
            }
        });
    }

    async getState() : Promise<GetStateResult>{
        if(this.state==null){
            this.state = this.contract.methods.getState().call();
        }else{
            return await this.state;
        }
        //let state: GetStateResult = await this.contract.methods.getState().call();
        this.state = await this.state;
        setTimeout(()=>this.state=null, 10000);
        return this.state;
    }

    async getConfiguration() : Promise<GetConfigurationResult>{
        if(this.configuration==null){
            this.configuration = this.contract.methods.getConfiguration().call();
        }else{
            return this.configuration;
        }
        this.configuration = await this.configuration;
        //setTimeout(()=>this.configuration=null, 10000);
        return this.configuration;
    }

    get submissions(): UDICESessionSubmissionsRegistry{
        return this._submissions2;
    }

    async getSubmission(submissionId:bigint) : Promise<UDICESubmission>{
       return this.submissions.getSubmission(submissionId);
    }

    async getLastSubmissions(offset: number, limit:number) : Promise<UDICESubmission[]>{
       return this.submissions.getLastSubmissions(offset, limit);
    }

    async getMyLastSubmissions(offset: number, limit: number) : Promise<UDICESubmission[]>{
        return this.submissions.getLastSubmissions(offset, limit);
    }

    addSubmissionsListener(listener: (submission : UDICESubmission)=>void) : {unsubscribe: () => void;}{
        return this._submissions2.addSubmissionsListener(listener);
    }

    addMySubmissionsListener(listener: (submission : UDICESubmission)=>void) : {unsubscribe: () => void;}{
        return this._submissions2.addPlayerSubmissionsListener(this.account, listener);
    }

    async getPlayerInfo(address:Address) : Promise<GetPlayerInfoResult>{
        return await this.methods.getPlayerInfo(address).call();
    }
    async getCurrentPlayerInfo() : Promise<GetPlayerInfoResult>{
        return await this.methods.getPlayerInfo(this.account).call();
    }


    async computeDonationDetails(submissionId : bigint, wager : bigint, prepaidProjectFee : bigint)
     : Promise<{donation: bigint, refundCoins: bigint, payoutTokens: bigint}>{
        let state = await this.getState();
        let configuration = await this.getConfiguration();
        let player = await this.getCurrentPlayerInfo();

        var donationLimit = configuration.projectFee * (BigInt(10) + player.submissionsCount);
        /*
        var donationLimit: bigint;
        if(state.rewardPool < configuration.minimumRewardPool){
            donationLimit = configuration.minimumRewardPool - state.rewardPool;
        }else{
            donationLimit = BigInt(0);
        }
        */
        var donationAmount:bigint;
        let refundCoins = prepaidProjectFee;
    
        if(donationLimit >= wager){
            donationAmount = wager;    
        }else{
            donationAmount = donationLimit;

            refundCoins += wager - donationAmount;
        }
        let payoutTokens = await this.epochize(submissionId, (donationAmount * configuration.maxRewardTokensPerEth) / (this.ether));

        return {"donation":donationAmount, "refundCoins":refundCoins, "payoutTokens":payoutTokens};
    }

    async estimatePlay(amount:bigint, multiplier:bigint, prepaidProjectFee:bigint): Promise<EstimatePlayResult>{
        let state = await this.getState();
        let configuration = await this.getConfiguration();

        let submissionId = state.submissionsCount + BigInt(1);

        //let emitter = this.contract.methods.estimatePlayWithFee(amount, multiplier, prepaidProjectFee).call();

        if(prepaidProjectFee > configuration.projectFee){
            prepaidProjectFee = configuration.projectFee;
        }
        if(prepaidProjectFee > amount){
            prepaidProjectFee = amount;
        }

        let wager:bigint;
        if(amount >= prepaidProjectFee){
            wager = amount - prepaidProjectFee;
        }else{
            wager = BigInt(0);
        }

        let onTheHouseProjectFee = configuration.projectFee - prepaidProjectFee;

        let houseEdge = this.computeHouseEdge(wager, prepaidProjectFee, configuration);
        let rewardableHouseEdge = this.computeRewardHouseEdge(wager, prepaidProjectFee, configuration);
        let bet = this.computeBet(wager, prepaidProjectFee, multiplier, configuration);
        let odds: number;
        if(bet==0){
            odds = 0;
        }else{
            odds = (65_536/bet);
        }
        let multiplierBoostTokens:bigint = await this.computeMultiplierBoostTokens(wager, multiplier);
        
        let response: EstimatePlayResult = {
            "wager":wager, "multiplier": multiplier, "prepaidProjectFee":prepaidProjectFee, "multiplierBoostTokens": multiplierBoostTokens,
            "bet": bet, "houseEdge": houseEdge,
            "status": 0,
            "odds": odds};
        
        let targetPayoutCoins : bigint = (wager * multiplier) / this.ether;
        let winingProbability: number;
        let losingProbability: number;
        let donationProbability: number;
        if(bet<=0){
            response.status = SUBMISSION_STATUS_LOST;
            winingProbability = 0; losingProbability = 1; donationProbability = 0;
        }else{
            /*
            if(state.rewardPool < onTheHouseProjectFee){
                response.status = SUBMISSION_STATUS_DONATED;
                winingProbability = 0; losingProbability = 0; donationProbability = 1;
            }else{
                response.status = SUBMISSION_STATUS_RESOLVING;
                winingProbability = bet / 65_536; 
                losingProbability = 1 - winingProbability;
                donationProbability = 0;
            }
            */
            response.status = SUBMISSION_STATUS_RESOLVING;
            winingProbability = bet / 65_536; 
            losingProbability = 1 - winingProbability;
            donationProbability = 0;
        }
        //1. won
        if(targetPayoutCoins < state.rewardPool){
            response.won = {
                probability: winingProbability,
                payoutCoins: targetPayoutCoins, 
                refundCoins: BigInt(0), 
                payoutTokens: BigInt(0)}
        }else{
            let compansatable = ((multiplier - this.ether) * wager)/ this.ether;
            if(compansatable <= BigInt(0)){
                compansatable = BigInt(0);
            }
            let payoutTokens = (compansatable * configuration.maxRewardTokensPerEth) / this.ether;
            let playerTokens = await this.epochize(submissionId, payoutTokens);
            response.won = {
                probability: winingProbability,
                payoutCoins : BigInt(0),
                refundCoins : wager,
                payoutTokens : playerTokens
            }
        }
        //2. lost
        if(bet <=0){
            //unwinnable bet
            //let amount = wager + prepaidProjectFee;
            //let payoutTokens = await this.epochize(submissionId, amount);
            let rewardTokensPotential = (rewardableHouseEdge * configuration.maxRewardTokensPerEth)/this.ether;
            let payoutTokens = await this.epochize(submissionId, rewardTokensPotential);
            response.lost = {
                probability: losingProbability,
                refundCoins : BigInt(0),
                payoutTokens : payoutTokens
            };
        }else{
            let rewardTokensPotential = (rewardableHouseEdge * configuration.maxRewardTokensPerEth)/this.ether;
            let newTokens = await this.epochize(submissionId, rewardTokensPotential);
            //let playerTokens = (newTokens * (BigInt(100)-configuration.projectTokenAllocationPercent))/BigInt(100);
            let playerTokens = newTokens;
            response.lost = {
                probability: losingProbability,
                refundCoins : BigInt(0),
                payoutTokens : playerTokens
            };
        }
        //3. donation
        let donationDetails = await this.computeDonationDetails(submissionId, wager, prepaidProjectFee);
        response.donated = {
            probability: donationProbability,
            donation: donationDetails.donation,
            refundCoins: donationDetails.refundCoins,
            payoutTokens: donationDetails.payoutTokens
        };

        return response;
    }

    async estimatePlayByMultiplier(params: {amount:bigint, multiplier:bigint, feeOnTheHouse: boolean}): Promise<EstimatePlayResult>{
        let prepaidProjectFee: bigint;
        if(params.feeOnTheHouse){
            prepaidProjectFee = BigInt(0);
        }else{
            let config = await this.getConfiguration();
            prepaidProjectFee = config.projectFee;
        }
        return this.estimatePlay(params.amount,params.multiplier, prepaidProjectFee);
    }
    async estimatePlayByChance(params: {amount:bigint, chanceRate:number, feeOnTheHouse: boolean}): Promise<EstimatePlayResult>{
        let prepaidProjectFee: bigint;
        let config = await this.getConfiguration();
        if(params.feeOnTheHouse){
            prepaidProjectFee = BigInt(0);
        }else{
            prepaidProjectFee = config.projectFee;
        }
        let wager = params.amount - prepaidProjectFee;

        let bet:number = Math.round(params.chanceRate * 65_536);
        let multiplier: bigint = this.computMultiplierFromBet(wager, prepaidProjectFee, bet, config);

        return this.estimatePlay(params.amount, multiplier, prepaidProjectFee);
    }
    async estimatePlayByBet(params: {amount:bigint, bet:number, feeOnTheHouse: boolean}): Promise<EstimatePlayResult>{
        let prepaidProjectFee: bigint;
        let config = await this.getConfiguration();
        if(params.feeOnTheHouse){
            prepaidProjectFee = BigInt(0);
        }else{
            prepaidProjectFee = config.projectFee;
        }
        let wager = params.amount - prepaidProjectFee;

        let bet:number = params.bet;
        let multiplier: bigint = this.computMultiplierFromBet(wager, prepaidProjectFee, bet, config);

        return this.estimatePlay(params.amount, multiplier, prepaidProjectFee);
    }
    async getAccountTokensBalance() : Promise<bigint>{
        const result = await this.contract.methods.balanceOf(this.account).call();
        return result as unknown as bigint;
    }
    async getTokensSymbol(){
        if(this._tokensSysmbol!=null) return this._tokensSysmbol;
        return this._tokensSysmbol = await this.contract.methods.symbol().call();
    }
    async getAccountCoinsBalance() : Promise<bigint>{
        const result = await this.web3.eth.getBalance(this.account);
        return result;
    }
    getCoinsSymbol(){
        return this.chainInfo.nativeCurrency.symbol;
    }

    addConfigurationChangedEventListener(listener: (configuration: GetConfigurationResult)=>void ) : {unsubscribe: ()=>void; }{
        if(!this._configurationChangedEventListener){
            this._configurationChangedEventListener = this.contract.events.ConfigurationChanged({filter: {}});
            this._configurationChangedEventListener.handlers = new Set();
            this._configurationChangedEventListener.on("data", event=>{
                this._configurationChangedEventListener.handlers.forEach(handler=>{
                    try{
                        handler(event.returnValues);
                    }catch(error){
                        console.log(error);
                    }
                });
            });
        }
        let handlers: Set<any> = this._configurationChangedEventListener.handlers;
        handlers.add(listener);
        return {"unsubscribe": ()=>{
            handlers.delete(listener);
        }};
    }
    addContractEventsListener(listener : (event: any)=>void){
        /*
        if(!this.transferEventsSubscription){
            this.transferEventsSubscription = this.contract.events.allEvents({filter: {}, fromBlock:"latest"});
            this.transferEventsSubscription.handlers = new Set();
            this.transferEventsSubscription.on("data", async event=>{
                if(!Config.isContractEvent(event.event)){
                    console.warn(`Unknown event ${event.event}: `, event);
                    return;
                }else{
                    console.info(`Known event ${event.event}`, event);
                }
                this.state = null;
                this.transferEventsSubscription.handlers.forEach(handler=>{
                    try{
                        handler(event);
                    }catch(error){
                        console.log(error);
                    }
                });
            });
        }
        let handlers:Set<any> = this.transferEventsSubscription.handlers;
        handlers.add(listener);
        return {'unsubscribe': ()=> {
            handlers.delete(listener); 
        }};
        */
       return this._submissions2.addContractEventsListener(listener);
    }

    addStateChangedEventListener(listener : (state: GetStateResult)=>void ) : {unsubscribe: ()=>void; } {
        return this.addContractEventsListener(async event=>{
            let state = await this.getState();
            listener(state);
        });
    }

    addInvalidationListener(listener) : {unsubscribe: ()=>void; } {
        let subscription1 = this.udiceProvider.addChainChangedEventListener(newChainId=>{
            if(newChainId!=this.chainId){
                listener();
            }
        });
        let subscription2 = this.udiceProvider.addAccountsChangedEventListener(accounts=>{
            if(accounts.indexOf(this.account)==-1){
                listener();
            }
        });

        this._subscriptions.add(subscription1);
        this._subscriptions.add(subscription2);
        return {'unsubscribe': ()=>{
            this._subscriptions.delete(subscription1);
            this._subscriptions.delete(subscription2);
        }};
    }

    async close(){
        if(this.transferEventsSubscription){
            this.transferEventsSubscription.unsubscribe();
        }
        if(this._configurationChangedEventListener){
            this._configurationChangedEventListener.unsubscribe();
        }
        this._subscriptions.forEach((subscription:any)=>{
            subscription.unsubscribe();
        });
    }

}

export interface UDICESubmission extends ISubmission{
    createdTimestamp: Promise<bigint>;
    resolvedTimestamp: Promise<bigint>;

    getSubmittedEvent(): Promise<SubmittedEvent>;
    getOutcomeEvent(): Promise<OutcomeEvent>;
    getAfterSubmittedEvent(): Promise<UDICESubmission>;
    getAfterOutcomeEvent(): Promise<UDICESubmission>;
    onComplete(): Promise<UDICESubmission>;
    
    addChangeListener(listener: (submission: UDICESubmission)=>void): void;

    refresh(): Promise<ISubmission>;
    refund(): Promise<ISubmission>;

    getTransactionInfo(): Promise<{transactionHash: string; transactionIndex: bigint;}>;
}

export interface UDICESessionSubmissionsRegistry{

    getSubmission(submissionId: bigint):Promise<UDICESubmission>;

    addSubmissionsListener(listener: (submission: UDICESubmission)=>void) : {unsubscribe: ()=>void};
    addPlayerSubmissionsListener(address: Address, listener: (submission: UDICESubmission)=>void) : {unsubscribe: ()=>void};

    getSubmissionSubmittedEvent(submissionId: bigint): Promise<SubmittedEvent>;
    getSubmissionAfterSubmittedEvent(submissionId: bigint): Promise<UDICESubmission>;

    getSubmissionOutcomeEvent(submissionId: bigint): Promise<OutcomeEvent>;
    getSubmissionAfterOutcomeEvent(submissionId: bigint): Promise<UDICESubmission>;

    getLastSubmissions(offset: number, limit:number) : Promise<UDICESubmission[]>;
    getMyLastSubmissions(offset: number, limit: number) : Promise<UDICESubmission[]>;

    querySubmissions(offset: number | bigint, limit:number | bigint, ascend:boolean) : Promise<UDICESubmission[]>;
    queryMySubmissions(offset: number | bigint, limit:number | bigint, ascend:boolean) : Promise<UDICESubmission[]>;

}

class UDICESessionSubmissionsRegistryEntrySubmission implements UDICESubmission{

    private _entry: UDICESessionSubmissionsRegistryEntryImpl;
    blockNumber: bigint;
    transactionHash: string;
    transactionIndex: bigint;
    submissionId: bigint;
    player: string;
    wager: bigint;
    prepaidProjectFee: bigint;
    projectFee: bigint;
    multiplier: bigint;
    multiplierBoostTokens: bigint;
    houseEdgeFixed: bigint; 
    houseEdgeRate: bigint;
    bet: number;
    status: number;
    outcome: ISubmissionOutcome;

    createdTimestamp: Promise<bigint>;
    resolvedTimestamp: Promise<bigint>;
    
    constructor(entry: UDICESessionSubmissionsRegistryEntryImpl){
        this._entry = entry;
    }

    getSubmittedEvent(): Promise<SubmittedEvent> {
        return this._entry.submittedEvent;
    }
    getOutcomeEvent(): Promise<OutcomeEvent> {
        return this._entry.outcomeEvent;
    }
    async getAfterSubmittedEvent(): Promise<UDICESubmission> {
        await this.getSubmittedEvent();
        return this;
    }
    async getAfterOutcomeEvent(): Promise<UDICESubmission> {
        await this.getOutcomeEvent();
        return this;
    }

    async onComplete(): Promise<UDICESubmission>{
        return await this._entry.onCompleted;
    }

    addChangeListener(listener: (submission: UDICESubmission) => void): {unsubscribe: ()=>void} {
        return this._entry.addChangeListener(listener);
    }
    async refresh(): Promise<ISubmission> {
        return this._entry.refresh();
    }
    async refund(): Promise<ISubmission> {
        return this._entry.refund();
    }

    async getTransactionInfo(): Promise<{transactionHash: string; transactionIndex: bigint;}>{
        if(this.transactionHash !=null){
            return {transactionHash: this.transactionHash, transactionIndex: this.transactionIndex};
        }
        //let submittedEventName = "Submitted";
        //let submittedEventSignature = this._entry.session.web3.utils.keccak256(submittedEventName);
        //let submittedEventSignature = '0xeb87ffbe69be596530037de78d6a6ca3317c12ce908c3c46e9cfa526531a177e';
        let block = await this._entry.session.web3.eth.getBlock(this.blockNumber);

        let _submittedEvent = this._entry.session.contract.events.Submitted();
        //console.log(_submittedEvent);
        
        if(block.transactions.length === 0 ){
            return {transactionHash:null, transactionIndex: null};
        }
        for(var i=0; i<block.transactions.length; i++){
            const tx = block.transactions[i];

            let transactionHash = typeof tx==="string"?tx:(tx as any).hash;
            let receipt = await this._entry.session.web3.eth.getTransactionReceipt(transactionHash);
            
            for(const log of receipt.logs){
                if(log.address.toLowerCase()===this._entry.session.contractInfo.contractAddress.toLowerCase()){
                    //console.log(log.address);
                    if(log.topics[0]===_submittedEvent.abi.signature){
                        const inputs = _submittedEvent.abi.inputs as any;
                        const data = this._entry.session.web3.utils.bytesToHex(log.data);
                        const topics = log.topics.map(item=>this._entry.session.web3.utils.bytesToHex(item));
                        let decoded = this._entry.session.web3.eth.abi.decodeLog(inputs, data, topics);
                        //console.log(decoded);
                        if(decoded.submissionId==this.submissionId){
                            this.transactionHash = transactionHash;
                            this.transactionIndex = BigInt(i);
                            return {transactionHash: this.transactionHash, transactionIndex: this.transactionIndex};
                        }
                    }
                }
            }
        }
        return {transactionHash: this.transactionHash, transactionIndex: this.transactionIndex};
    }
    
}

export interface UDICESessionSubmissionsRegistryEntry{
    submissionId : bigint;
    player: Address;
    getSubmission(): Promise<UDICESubmission>;
    addChangeListener(listener: (submission: UDICESubmission) => void): {unsubscribe: ()=>void};
}

class UDICESessionSubmissionsRegistryEntryImpl implements UDICESessionSubmissionsRegistryEntry{

    static fromSubmittedEvent(session : UDICESession, submittedEvent: SubmittedEvent): UDICESessionSubmissionsRegistryEntryImpl{
        let result = new UDICESessionSubmissionsRegistryEntryImpl(session, submittedEvent.returnValues.submissionId);
        result.submittedEvent = submittedEvent;
        result.player = submittedEvent.returnValues.player;
        return result;
    }


    static fromSubmission(session : UDICESession, submission: ISubmission): UDICESessionSubmissionsRegistryEntryImpl{
        let result = new UDICESessionSubmissionsRegistryEntryImpl(session, submission.submissionId);
        result.submissionRecord = submission;
        result.player = submission.player;
        return result;
    }
    
    static fromOutcomeEvent(session: UDICESession, outcomeEvent: OutcomeEvent): UDICESessionSubmissionsRegistryEntryImpl{
        let result = new UDICESessionSubmissionsRegistryEntryImpl(session, outcomeEvent.returnValues.submissionId);
        result.outcomeEvent = outcomeEvent;
        result.player = outcomeEvent.returnValues.player;
        return result;
    }

    private _session: UDICESession;
    private _submission: UDICESubmission;
    private _submissionId: bigint;

    private _submissionPromise: PromiseWithResolvers<UDICESubmission> = Promise.withResolvers();
    private _submissionRecord: PromiseWithResolvers<ISubmission> = Promise.withResolvers();
    private _submittedEvent: PromiseWithResolvers<SubmittedEvent> = Promise.withResolvers();
    private _outcomeEvent: PromiseWithResolvers<OutcomeEvent> = Promise.withResolvers();
    private _onCompleted: PromiseWithResolvers<UDICESubmission> = Promise.withResolvers();
    private _listeners: Set<(submission: UDICESubmission) => void> = new Set();
    sent: boolean = false;
    player: Address;

    constructor(session: UDICESession, submissionId : bigint){
        this._session = session;
        this._submissionId = submissionId;
 
        this._submissionRecord.promise.then(record=>{
            /*
            if(this._submission==null){
                this._submission = new UDICESessionSubmissionsRegistryEntrySubmission(this);
            }
            this._submission.blockNumber = record.blockNumber;
            if(this._submission.createdTimestamp==null){
                this._submission.createdTimestamp = session.getBlockTimestamp(record.blockNumber);   
            }
            this._submission.submissionId = record.submissionId;
            this._submission.player = record.player; 
            this._submission.wager = record.wager;
            this._submission.prepaidProjectFee = record.prepaidProjectFee;
            this._submission.projectFee = record.projectFee;
            this._submission.multiplier = record.multiplier;
            this._submission.multiplierBoostTokens = record.multiplierBoostTokens;
            this._submission.houseEdgeFixed = record.houseEdgeFixed;
            this._submission.houseEdgeRate = record.houseEdgeRate;
            this._submission.bet = record.bet;
            this._submission.status = record.status;
            if(record.status!=SUBMISSION_STATUS_RESOLVING){
                this._submission.outcome = {} as ISubmissionOutcome;
                this._submission.outcome.roll = record.outcome.roll;
                this._submission.outcome.payoutCoins = record.outcome.payoutCoins;
                this._submission.outcome.payoutTokens = record.outcome.payoutTokens;
                this._submission.outcome.refundCoins = record.outcome.refundCoins;
            }
            this._submissionPromise.resolve(this._submission);
            this.triggerSubmissionChangedEvent();
            */
        });
        
        this._submittedEvent.promise.then(event=>{
            /*
            if(this._submission==null){
                this._submission = new UDICESessionSubmissionsRegistryEntrySubmission(this);
            }
            this._submission.blockNumber = event.blockNumber;
            if(this._submission.createdTimestamp==null){
                this._submission.createdTimestamp = session.getBlockTimestamp(event.blockNumber);
            }
            this._submission.submissionId = event.returnValues.submissionId;
            this._submission.player = event.returnValues.player; 
            this._submission.wager = event.returnValues.wager;
            this._submission.prepaidProjectFee = event.returnValues.prepaidProjectFee;
            this._submission.projectFee = event.returnValues.config?.projectFee;
            this._submission.multiplier = event.returnValues.multiplier;
            this._submission.multiplierBoostTokens = event.returnValues.multiplierBoostTokens;
            this._submission.bet = event.returnValues.bet;
            if(this._submission.status==null){
                this._submission.status = event.returnValues.status; 
                //this._submission.roll = record.roll;
                //this._submission.payoutCoins = record.payoutCoins;
                //this._submission.payoutTokens = record.payoutTokens;
                //this._submission.refundCoins = record.refundCoins;
            }
            this._submissionPromise.resolve(this._submission);
            this.triggerSubmissionChangedEvent();
            */
        });
        this._outcomeEvent.promise.then(event=>{
            /*
            if(this._submission==null){
                this._submission = new UDICESessionSubmissionsRegistryEntrySubmission(this);
            } 
            this._submission.resolvedTimestamp = session.getBlockTimestamp(event.blockNumber);
            this._submission.submissionId = event.returnValues.submissionId;
            this._submission.player = event.returnValues.player;
            this._submission.wager = event.returnValues.wager;
            this._submission.prepaidProjectFee = event.returnValues.prepaidProjectFee;
            this._submission.projectFee = event.returnValues.config?.projectFee;
            this._submission.multiplier = event.returnValues.multiplier;

            this._submission.status = event.returnValues.status;

            this._submission.outcome = {} as ISubmissionOutcome;
            this._submission.outcome.roll = event.returnValues.outcome.roll;
            this._submission.outcome.payoutCoins = event.returnValues.outcome.payoutCoins;
            this._submission.outcome.payoutTokens = event.returnValues.outcome.payoutTokens;
            this._submission.outcome.refundCoins = event.returnValues.outcome.refundCoins;
            this.triggerSubmissionChangedEvent();
            this._submissionPromise.resolve(this._submission);
            */
        });
    }
    get session(): UDICESession{ return this._session}

    get submissionId() : bigint{
        return this._submissionId;
    }

    async getSubmission(): Promise<UDICESubmission>{
        let result = await this._submissionPromise.promise;
        return result;
    }

    get submissionRecord(): Promise<ISubmission>{
        return this._submissionRecord.promise;
    }
    set submissionRecord(record:ISubmission){
        if(this._submission==null){
            this._submission = new UDICESessionSubmissionsRegistryEntrySubmission(this);
        }
        this._submission.blockNumber = record.blockNumber;
        if(record.transactionHash){
            this._submission.transactionHash = record.transactionHash;
        }
        if(record.transactionIndex){
            this._submission.transactionIndex = record.transactionIndex;
        }
        if(this._submission.createdTimestamp==null){
            this._submission.createdTimestamp = this._session.getBlockTimestamp(record.blockNumber);   
        }
        this._submission.submissionId = record.submissionId;
        this._submission.player = record.player; 
        this._submission.wager = record.wager;
        this._submission.prepaidProjectFee = record.prepaidProjectFee;
        this._submission.projectFee = record.projectFee;
        this._submission.multiplier = record.multiplier;
        this._submission.multiplierBoostTokens = record.multiplierBoostTokens;
        this._submission.houseEdgeFixed = record.houseEdgeFixed;
        this._submission.houseEdgeRate = record.houseEdgeRate;
        this._submission.bet = record.bet;
        this._submission.status = record.status;
        if(record.status!=SUBMISSION_STATUS_RESOLVING){
            this._submission.outcome = {} as ISubmissionOutcome;
            this._submission.outcome.roll = record.outcome.roll;
            this._submission.outcome.payoutCoins = record.outcome.payoutCoins;
            this._submission.outcome.payoutTokens = record.outcome.payoutTokens;
            this._submission.outcome.refundCoins = record.outcome.refundCoins;
        }
        this._submissionPromise.resolve(this._submission);
        this.triggerSubmissionChangedEvent();

        this._submissionRecord.resolve(record);

        if(this._submission.status > SUBMISSION_STATUS_RESOLVING){
            this._onCompleted.resolve(this._submission);
        }
    }
    get submittedEvent() : Promise<SubmittedEvent>{
        return this._submittedEvent.promise;
    }
    set submittedEvent(event : SubmittedEvent){
        if(this._submission==null){
            this._submission = new UDICESessionSubmissionsRegistryEntrySubmission(this);
        }
        this._submission.blockNumber = event.blockNumber;
        this._submission.transactionHash = event.transactionHash;
        this._submission.transactionIndex = event.transactionIndex;
        if(this._submission.createdTimestamp==null){
            this._submission.createdTimestamp = this._session.getBlockTimestamp(event.blockNumber);
        }
        this._submission.submissionId = event.returnValues.submissionId;
        this._submission.player = event.returnValues.player; 
        this._submission.wager = event.returnValues.wager;
        this._submission.prepaidProjectFee = event.returnValues.prepaidProjectFee;
        this._submission.projectFee = event.returnValues.config?.projectFee;
        this._submission.multiplier = event.returnValues.multiplier;
        this._submission.multiplierBoostTokens = event.returnValues.multiplierBoostTokens;
        this._submission.bet = event.returnValues.bet;
        if(this._submission.status==null){
            this._submission.status = event.returnValues.status; 
            //this._submission.roll = record.roll;
            //this._submission.payoutCoins = record.payoutCoins;
            //this._submission.payoutTokens = record.payoutTokens;
            //this._submission.refundCoins = record.refundCoins;
        }
        this._submissionPromise.resolve(this._submission);
        this.triggerSubmissionChangedEvent();
        
        this._submittedEvent.resolve(event);
    }
    get outcomeEvent() : Promise<OutcomeEvent>{
        return this._outcomeEvent.promise;
    }
    set outcomeEvent(event : OutcomeEvent){
        if(this._submission==null){
            this._submission = new UDICESessionSubmissionsRegistryEntrySubmission(this);
        } 
        this._submission.resolvedTimestamp = this._session.getBlockTimestamp(event.blockNumber);
        this._submission.submissionId = event.returnValues.submissionId;
        this._submission.player = event.returnValues.player;
        this._submission.wager = event.returnValues.wager;
        this._submission.prepaidProjectFee = event.returnValues.prepaidProjectFee;
        //this._submission.projectFee = event.returnValues.config?.projectFee;
        this._submission.multiplier = event.returnValues.multiplier;
        this._submission.multiplierBoostTokens = event.returnValues.multiplierBoostTokens;
        this._submission.bet = event.returnValues.bet;

        this._submission.status = event.returnValues.status;

        this._submission.outcome = {} as ISubmissionOutcome;
        this._submission.outcome.roll = event.returnValues.outcome.roll;
        this._submission.outcome.payoutCoins = event.returnValues.outcome.payoutCoins;
        this._submission.outcome.payoutTokens = event.returnValues.outcome.payoutTokens;
        this._submission.outcome.refundCoins = event.returnValues.outcome.refundCoins;

        //force a refresh if submissionRecord and submittedEvent are not available in 10 seconds
        let timer = setTimeout(()=>{
            this.refresh();
        }, 10_000);

        Promise.race([this.submissionRecord, this.submittedEvent])
            .then(()=>{
                clearTimeout(timer);

                this.triggerSubmissionChangedEvent();
                this._submissionPromise.resolve(this._submission);

                this._outcomeEvent.resolve(event);
                this._onCompleted.resolve(this._submission);
            });
        
        
    }

    get onCompleted(): Promise<UDICESubmission>{
        return this._onCompleted.promise;
    }

    addChangeListener(listener: (submission: UDICESubmission) => void): {unsubscribe: ()=>void} {
        this._listeners.add(listener);
        return {unsubscribe: ()=> this._listeners.delete(listener)};
    }

    triggerSubmissionChangedEvent() {
        this.getSubmission().then(submission=>{
            this._listeners.forEach(listener=>{
                try{
                    listener(submission);
                }catch(error){
                    console.log(error);
                }
            });
        }).catch(error=>{
            console.log(error);
        });

    }
    async refresh(): Promise<ISubmission> {
        let submission: ISubmission = await this.session.methods.getSubmission(this.submissionId).call();
        if(submission.status > SUBMISSION_STATUS_RESOLVING){
            this.submissionRecord = submission;
        }
        return submission;
    }
    async refund(): Promise<ISubmission> {
        let receipt = await this.session.methods.refund(this.submissionId).send();
        let submission: ISubmission = await this.session.methods.getSubmission(this.submissionId).call();
        if(submission.status > SUBMISSION_STATUS_RESOLVING){
            this.submissionRecord = submission;
        }
        return submission;
    }
}

class UDICESessionSubmissionsRegistryImpl implements UDICESessionSubmissionsRegistry{
    
    private _session: UDICESession;
    private _submittedSubscription: any;
    private _outcomeSubscription: any;
    private _submissions: Map<bigint, UDICESessionSubmissionsRegistryEntryImpl>;
    private _listeners: Set<(submission: UDICESubmission) => void> = new Set();
    private _eventsListeners = new Set<(event: any)=>void>();

    constructor(session : UDICESession){
        this._session = session;
        this._submissions = new Map();
        this.initialize();
    }
    
    addContractEventsListener(listener : (event: any)=>void): {"unsubscribe": ()=>void}{
        this._eventsListeners.add(listener);
        return {"unsubscribe": ()=> this._eventsListeners.delete(listener)};
    }

    fireContractEventsListeners(event: any){
        this._eventsListeners.forEach(listener=>{
            try{
                listener(event);
            }catch(error){
                console.error(error);
            }
        });
    }

    async initialize(){
        //let submissions = await this._session.contract.methods.getLastSubmissions(0, 10).call();
        let submissions = await this._session.methods.querySubmissions(0, 10, false).call();
        let currentBlockNumber = await this._session.web3.eth.getBlockNumber();
        
        let blockNumber: uint;
        if(submissions.length==0){
            blockNumber = currentBlockNumber;
        }else{
            let max: bigint = BigInt(1000);
            let lastBetBlockNumber = submissions[submissions.length-1].blockNumber;

            blockNumber = currentBlockNumber-lastBetBlockNumber;
            if(currentBlockNumber-lastBetBlockNumber > max){
                blockNumber = currentBlockNumber - max;
            }else{
                blockNumber = lastBetBlockNumber;
            }
        }
            
        //let blockNumber = "latest";
        this.loadSubmissions(submissions);

        this._submittedSubscription = this._session.streamContract.events.allEvents({fromBlock: blockNumber});
        this._submittedSubscription.on("data", event=>{
            if(this._session.contractInfo.isContractEvent(event.event)){
                if(event.event=='Submitted'){
                    this._onNewSubmittedEvent(event);
                }else if(event.event=='Outcome'){
                    this._onNewOutcomeEvent(event);
                }else{
                    console.log("Unknown contract event: "+event.event, event);
                }
            }else{
                console.log("Unknown event: "+event.event, event);
            }
            this.fireContractEventsListeners(event);
        });
        this._submittedSubscription.on("error", (error, receipt)=>{
            console.log(error);
            if(receipt!=null) console.log(receipt);
        });
        /*
        let x = this._session.streamContract.events.Submitted({fromBlock: "latest"});
        x.on("data", event=>{
                console.log(event);
            });
        x.on("error", error=>{
            console.log(error);
        });

        let y = this._session.streamContract.events.Outcome({fromBlock: "latest"});
        y.on("data", event=>{
                console.log(event);
            });
        y.on("error", error=>{
            console.log(error);
        });
        */
    }

    getOrCreateEntry(submissionId: bigint, factory: ()=>UDICESessionSubmissionsRegistryEntryImpl): UDICESessionSubmissionsRegistryEntryImpl{
        var entry = this._submissions.get(submissionId);
        if(entry==null){
            entry = factory();
            this._submissions.set(submissionId, entry);
        }
        return entry;
    }

    _onNewSubmittedEvent(event: SubmittedEvent) : Promise<UDICESubmission> {
        let submissionId = event.returnValues.submissionId;
        var entry = this._submissions.get(submissionId);
        if(entry==null){
            entry = UDICESessionSubmissionsRegistryEntryImpl.fromSubmittedEvent(this._session, event);
            this._submissions.set(submissionId, entry);
        }else{
            entry.submittedEvent = event;
        }
        if(entry.sent==false){
            this.triggerNewSubmissionEvent(entry);
            entry.sent = true;
        }else{
            this.triggerSubmissionChangedEvent(entry);
        }

        return entry.getSubmission();
    }

    async triggerNewSubmissionEvent(entry: UDICESessionSubmissionsRegistryEntryImpl) {
        this._listeners.forEach(listener=>{
            entry.getSubmission()
                .then(submission=>listener(submission))
                .catch(error=>{
                    console.log(error);
                });
        });
    }

    triggerSubmissionChangedEvent(entry: UDICESessionSubmissionsRegistryEntryImpl) {
        entry.triggerSubmissionChangedEvent();
    }

    private _onNewOutcomeEvent(event: OutcomeEvent) : Promise<UDICESubmission>  {
        let submissionId = event.returnValues.submissionId;
        var entry = this._submissions.get(submissionId);
        if(entry==null){
            entry = UDICESessionSubmissionsRegistryEntryImpl.fromOutcomeEvent(this._session, event);
            this._submissions.set(submissionId, entry);
            this.triggerNewSubmissionEvent(entry);
        }else{
            entry.outcomeEvent = event;
            this.triggerSubmissionChangedEvent(entry);
        }
        return entry.getSubmission();
    }

    async getSubmission(submissionId: bigint): Promise<UDICESubmission> {
        let existingEntry = this._submissions.get(submissionId);
        if(existingEntry!=null){
            return existingEntry.getSubmission();
        }
        //let submission = await this._session.contract.methods.getSubmission(submissionId);
        let submission = await this._session.methods.getSubmission(submissionId).call();

        let entry = UDICESessionSubmissionsRegistryEntryImpl.fromSubmission(this._session, submission);
        this._submissions.set(submissionId, entry);

        return entry.getSubmission();
    }

    listSubmissions() : Promise<UDICESubmission>[] {
        let result = [...this._submissions.keys()].toSorted().map(submissionId=>{
            return this._submissions.get(submissionId).getSubmission();
        })
        ;
        return result;
    }

    listPlayerSubmissions(player: Address) : Promise<UDICESubmission>[] {
        let result = [...this._submissions.keys()]
        .toSorted().map(submissionId=>{
            return this._submissions.get(submissionId).getSubmission();
        })
        ;
        return result;
    }

    addSubmissionsListener(listener: (submission: UDICESubmission) => void): { unsubscribe: () => void; } {
        this._listeners.add(listener);
        return {unsubscribe: ()=>this._listeners.delete(listener)};
    }
    addPlayerSubmissionsListener(address: string, listener: (submission: UDICESubmission) => void): { unsubscribe: () => void; } {
        //this._session.contract.methods.getPlayerLastSubmissions(address, 0, 10).call()
        this._session.methods.queryPlayerSubmissions(address, 0, 10, false).call()
            .then((playerSubmissions: ISubmission[])=>{
                playerSubmissions
                    .map(submission=>{
                        var entry = this._submissions.get(submission.submissionId);
                        if(entry==null){
                            entry = UDICESessionSubmissionsRegistryEntryImpl.fromSubmission(this._session, submission);
                            this._submissions.set(submission.submissionId, entry);
                        }
                        return entry;
                    }).sort((a,b)=> Number(b.submissionId - a.submissionId))
                    .forEach(async item=>listener(await item.getSubmission()));
                return playerSubmissions;
            });

        return this.addSubmissionsListener(submission=>{
            if(submission.player.toLowerCase()==address.toLowerCase()){
                listener(submission);
            }
        });
    }

    async getSubmissionSubmittedEvent(submissionId: bigint): Promise<SubmittedEvent> {
        let submission = await this.getSubmission(submissionId);
        return submission.getSubmittedEvent();
    }
    async getSubmissionAfterSubmittedEvent(submissionId: bigint): Promise<UDICESubmission> {
        let submission = await this.getSubmission(submissionId);
        return submission.getAfterSubmittedEvent();
    }
    async getSubmissionOutcomeEvent(submissionId: bigint): Promise<OutcomeEvent> {
        let submission = await this.getSubmission(submissionId);
        return submission.getOutcomeEvent();
    }
    async getSubmissionAfterOutcomeEvent(submissionId: bigint): Promise<UDICESubmission> {
        let submission = await this.getSubmission(submissionId);
        return submission.getAfterOutcomeEvent();
    }

    async querySubmissions(offset: number | bigint, limit:number | bigint, ascend:boolean){
        let submissions: ISubmission[] = await this._session.methods.querySubmissions(offset, limit, ascend).call();
        return this.loadSubmissions(submissions);
    }

    async queryMySubmissions(offset: number | bigint, limit:number | bigint, ascend:boolean){
        let player = this._session.account;
        let submissions = await this._session.methods.queryPlayerSubmissions(player, offset, limit, ascend).call();
        return this.loadSubmissions(submissions);
    }

    async getLastSubmissions(offset: number | bigint, limit:number | bigint) : Promise<UDICESubmission[]>{
        //let submissions: SubmissionRecord[] = await this._session.contract.methods.getLastSubmissions(offset, limit).call();
        let submissions: ISubmission[] = await this._session.methods.querySubmissions(offset, limit, false).call();
        /*
        let result : UDICESubmission[] = [];
        for(var i=0; i<submissions.length; i++){
            let entry : UDICESessionSubmissionsRegistryEntryImpl = this.getOrCreateEntry(submissions[i].submissionId, ()=>{
                return UDICESessionSubmissionsRegistryEntryImpl.fromSubmission(this._session, submissions[i]);
            });
            result.push(await entry.getSubmission());
        }
        return result;
        */
       return this.loadSubmissions(submissions);
    }

    async getMyLastSubmissions(offset: number | bigint, limit: number | bigint) : Promise<UDICESubmission[]>{
        //let submissions = await this._session.contract.methods.getMyLastSubmissions(offset, limit).call();
        let player = this._session.account;
        let submissions = await this._session.methods.queryPlayerSubmissions(player, offset, limit, false).call();
        /*
        let result: UDICESubmission[] = [];
        for(var i=0; i<submissions.length; i++){
            let entry : UDICESessionSubmissionsRegistryEntryImpl = this.getOrCreateEntry(submissions[i].submissionId, ()=>{
                return UDICESessionSubmissionsRegistryEntryImpl.fromSubmission(this._session, submissions[i]);
            });
            result.push(await entry.getSubmission());
        }
        return result;
        */
       return this.loadSubmissions(submissions);
    }

    private async loadSubmissions(submissions: ISubmission[] ): Promise<UDICESubmission[]>{
        let result : UDICESubmission[] = [];
        for(var i=0; i<submissions.length; i++){
            let entry : UDICESessionSubmissionsRegistryEntryImpl = this.getOrCreateEntry(submissions[i].submissionId, ()=>{
                return UDICESessionSubmissionsRegistryEntryImpl.fromSubmission(this._session, submissions[i]);
            });
            result.push(await entry.getSubmission());

            this.triggerNewSubmissionEvent(entry);
            this.triggerSubmissionChangedEvent(entry);
        }
        return result;
    }

}