import {default as BootstrapModal} from "bootstrap/js/src/modal.js";

/*
Promise.withResolvers || (Promise.withResolvers = function withResolvers() {
    var a, b, c = new this(function (resolve, reject) {
      a = resolve;
      b = reject;
    });
    return {resolve: a, reject: b, promise: c};
  });
*/
export class UDICEDialogOptions{
    static show = showDialog;

    fullscreen?: boolean = true;
    scrollable?: boolean = true;
    titleHtml?: string | HTMLElement = "";
    bodyHtml: string | HTMLElement = "";
    footerHtml? : string | HTMLElement;
    footerAppendableHtml? : string | HTMLElement = "";

    onClosed?: ()=>void = null;
    onHidden?: ()=>void = null;
}

async function showDialog(options: UDICEDialogOptions): Promise<void>{

    let modalElement: HTMLDivElement = document.createElement('div');
    modalElement.classList.add("modal", "fade");
    modalElement.tabIndex = -1;
    modalElement.innerHTML = `
            <div class="modal-dialog ${options.fullscreen?'modal-fullscreen':''} ${options.scrollable?'modal-dialog-scrollable':''} modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex justify-content-center">
                    <p>Modal body text goes here.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
            </div>

    `;
    //let modalElement = wrapper.children.item(0);
    let bodyElement = document.querySelector("body");
    bodyElement.appendChild(modalElement);
    
    modalElement.querySelector(".modal-title").innerHTML = ``;
    if(typeof options.titleHtml == "string"){
        modalElement.querySelector(".modal-title").innerHTML = options.titleHtml;
    }else if(options.titleHtml != null){
        modalElement.querySelector(".modal-title").appendChild(options.titleHtml);
    }

    modalElement.querySelector(".modal-body").innerHTML = ``;
    if(typeof options.bodyHtml == "string"){
        modalElement.querySelector(".modal-body").innerHTML = options.bodyHtml;
    }else if(options.bodyHtml!=null){
        modalElement.querySelector(".modal-body").appendChild(options.bodyHtml);
    }
    if(typeof options.footerHtml == "string"){
        modalElement.querySelector(".modal-footer").innerHTML = options.footerHtml;
    }else if(options.footerHtml != null){
        modalElement.querySelector(".modal-footer").appendChild(options.footerHtml);
    }

    if(typeof options.footerAppendableHtml == "string"){
        modalElement.querySelector(".modal-footer").innerHTML = options.footerAppendableHtml;
    }else if(options.footerAppendableHtml != null){
        modalElement.querySelector(".modal-footer").appendChild(options.footerAppendableHtml);
    }
    //var _modal: BootstrapModal;
    this._modal = new BootstrapModal(modalElement, {backdrop: true, focus: true, keyboard: true});
    this._modal.show();

    let result = Promise.withResolvers<void>();
    modalElement.addEventListener("hidden.bs.modal", e=>{
        this._modal.dispose();
        bodyElement.removeChild(modalElement);
        
        result.resolve(null);

        if(this.onClosed!=null){
            this.onClosed();
        }
        if(this.onHidden!=null){
            this.onHidden();
        }
    });
    return result.promise;
}

export class UDICEDialog{

    static show = showDialog;

    fullscreen: boolean = true;
    scrollable: boolean = true;
    titleContent : string;
    bodyContent : HTMLElement;
    footerContent : HTMLElement;
    footerAppendableContent : HTMLElement;

    onClosed: ()=>void;
    onHidden: ()=>void;
    private _modal: BootstrapModal;

    constructor(){
    }
    show(){
        if(this._modal!=null){
            throw new Error("Already shown");
        }
        let wrapper = document.createElement('div');
        wrapper.innerHTML = `
            <div class="modal fade" tabindex="-1">
                <div class="modal-dialog ${this.fullscreen?'modal-fullscreen':''} ${this.scrollable?'modal-dialog-scrollable':''} modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body d-flex justify-content-center">
                        <p>Modal body text goes here.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
                </div>
            </div>        
        `;
        let modalElement: HTMLDivElement = document.createElement('div');
        modalElement.classList.add("modal", "fade");
        modalElement.tabIndex = -1;
        modalElement.innerHTML = `
                <div class="modal-dialog ${this.fullscreen?'modal-fullscreen':''} ${this.scrollable?'modal-dialog-scrollable':''} modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body d-flex justify-content-center">
                        <p>Modal body text goes here.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
                </div>
   
        `;
        //let modalElement = wrapper.children.item(0);
        let bodyElement = document.querySelector("body");
        bodyElement.appendChild(modalElement);
        
        modalElement.querySelector(".modal-title").innerHTML = ``;
        if(this.titleContent!=null){
            modalElement.querySelector(".modal-title").innerHTML = this.titleContent;
        }

        modalElement.querySelector(".modal-body").innerHTML = ``;
        if(this.bodyContent!=null){
            modalElement.querySelector(".modal-body").appendChild(this.bodyContent);
        }

        if(this.footerContent!=null){
            modalElement.querySelector(".modal-footer").innerHTML = ``;
            modalElement.querySelector(".modal-footer").appendChild(this.footerContent);
        }
        if(this.footerAppendableContent!=null){
            modalElement.querySelector(".modal-footer").appendChild(this.footerAppendableContent);
        }
        this._modal = new BootstrapModal(modalElement, {backdrop: true, focus: true, keyboard: true});
        this._modal.show();

        modalElement.addEventListener("hidden.bs.modal", e=>{
            this._modal.dispose();
            bodyElement.removeChild(modalElement);
            if(this.onClosed!=null){
                this.onClosed();
            }
            if(this.onHidden!=null){
                this.onHidden();
            }
        });
    }

    close(){
        this._modal.hide();
        //this.modal.dispose();
    }
}

export class UDICEUtils{
    
    static hideElement(element: HTMLElement){
        element.style.display = "none";
    }
    
    static unhideElement(element: HTMLElement){
        element.style.display = "";
        element.classList.remove("d-none");
    }

    static formatRelativeTime(date: Date) {
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);
    
        if (years > 0) {
            //return years === 1 ? "1 year ago" : `${years} years ago`;
            return "";
        } else if (months > 0) {
            //return months === 1 ? "1 month ago" : `${months} months ago`;
            return "";
        } else if (days > 0) {
            return days === 1 ? "1 day ago" : `${days} days ago`;
        } else if (hours > 0) {
            return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
        } else if (minutes > 0) {
            return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
        } else {
            return "Just now";
        }
    }
    
}

export class SplashScreen extends HTMLElement{
    private _created: number;
    constructor(){
        super();
 
        this.innerHTML = `
        <div class="d-flex justify-content-center mt-5">
            <!--
            <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
            </div>
            -->
            <img src="/img/dice-game.gif" width="100" height="100" />
        </div>
        <div class="d-flex justify-content-center mt-5">
            <div class="text-center">
                <h1 class="display-1 mt-5">UDUH</h1>
                <div class="display-3">
                    You dice, you house, yoo-doo!
                </div>
                <div class="display-60 mt-4 pt-4 border-top">World's first 100% player ownable polyhedral-dice game, with super-customizable odds, rewards up to 60,000X of wager</div>
            </div>
        </div>
        `;

        if(document.readyState!="complete"){
            window.addEventListener("load", e=> this.remove());
        }
        this._created = Date.now();
    }
    remove(){
        let delay = 2000 - (Date.now()-this._created);
        if(delay>0){
            setTimeout(()=>super.remove(), delay);
        }
    }
}
customElements.define('udice-splash-screen', SplashScreen);

export class ProgressScreen extends HTMLElement{
    public static show(): ProgressScreen{
        let screen = new ProgressScreen();
        document.querySelector("body").appendChild(screen);
        return screen;
    }
    constructor(){
        super();
        this.innerHTML = `
            <img src="/img/dice-game.gif" width="50" height="50">
        `;
        this.style.position = "fixed";
        this.style.top = "30%";
        this.style.left = "50%";
        //this.style.backgroundColor = "#112233AA";
        this.style.borderRadius = "240px";
        //this.style.padding = "40px";
        //this.style.border = "4px solid #222222AA";
        this.style.transform = "translate(-50%, -50%)";
    }
}
customElements.define('udice-progress-screen', ProgressScreen);