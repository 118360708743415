import "./udice-app-analytics.ts";

import "./udice.config.ts";
import "./main.ts";
import { SplashScreen, UDICEDialog, UDICEDialogOptions } from "./udice-app-utils.ts";
import { ChainInfo, ContractsRegistry, UdiceContractInfo, bnb_icon, hpb_icon, tt_icon } from "./udice.config.ts";
import { CountDown } from "./udice-app-countdown.ts";
import { showRulesPage } from "./udice-app-rules.ts";
import { UDICENav } from "./udice-app-nav.ts";
import { ReferralManager } from "./udice-app-referrer.ts";

ReferralManager.init();

class UdiceContractInfoWithButton{
    readonly contractInfo: UdiceContractInfo;
    readonly button: HTMLAnchorElement;
}

export class UDICELanding extends HTMLElement{

    constructor(){
        super();
        
        this.innerHTML = `
            <!-- delete
            <div class="d-flex justify-content-center mt-5 d-none">
                <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            -->

            <div class="d-flex justify-content-center mt-3">
                <div class="col-xl-6 col-md-8 col-sm-10 col-12 rounded border text-center text-bg-primary">
                    <h1 class="display-1 mt-1"><img src="/img/logo.png" width="48" height="48"> UDUH</h1>
                    <div class="display-6 text-bg-secondary p-3">
                        <span style="white-space: nowrap">You dice,</span> 
                        <span style="white-space: nowrap">you house,</span> 
                        <span style="white-space: nowrap">yoo-doo!<span>
                    </div>
                    <udice-countdown class="countdown" class1="d-flex justify-content-center display-4 mt-2 text-bg-info"></udice-countdown>

                    <h2 class="mt-5 border-bottom">Choose your favorite community:</h2>
                    <div class="list-group m-3 mt-1"></div>

                    <button class="btn btn-dark btn-rules mb-3">
                        <i class="bi bi-file-earmark-text"></i>
                        Rules
                    </button>
                </div>
            </div>

        `;
        let mainCountDown: CountDown = this.querySelector("udice-countdown");
        let listGroup = document.querySelector(".list-group");

        let chains = UDICELanding.buildChainList();
        
        let  nextLaunchTime: Date = ContractsRegistry.getInstance().list().map(item=>item.launchTime)
            .filter(a=>a.getTime()>Date.now() - (1000 * 60 * 60 * 24 * 7 ))
            .reduce((a,b)=>a.getTime()<=b.getTime()?a:b);
        
        mainCountDown.date = chains.nextLaunchTime;
        if(nextLaunchTime!=null && nextLaunchTime.getTime() < Date.now()){
            mainCountDown.remove();
        }
        
        chains.chains.forEach(chainInfo=>{
            let button = chainInfo.button;
            listGroup.appendChild(button);
        });
        this.querySelector(".btn-rules").addEventListener("click", e=>{
            showRulesPage();
        });
    }

    static chainUrl(chainInfo: UdiceContractInfo){
        if(chainInfo.iconUrl==bnb_icon){
            return "/bnb";
        }else if(chainInfo.iconUrl==tt_icon){
            return "/tt";
        }else if(chainInfo.iconUrl==hpb_icon){
            return "/hpb";
        }else{
            return null;
        }
    }
    static createButton(contractInfo: UdiceContractInfo){
        let button = document.createElement("a");
        button.classList.add("list-group-item");
        let url = UDICELanding.chainUrl(contractInfo);
        if(url==null){
            return;
        }
        button.href = url;
        //button.target = "_blank";
        button.innerHTML = `
            <img src="${contractInfo.iconUrl}" width="48" height="48" ${contractInfo.testnet?' style="filter: grayscale(1);"':''}></img>    
            <span class="h5">${contractInfo.displayName} <i class="bi bi-rocket"></i></span>

            <udice-countdown class="countdown-inline"></udice-countdown>

            &rarr;
    
        `;
        let countDown = button.querySelector("udice-countdown") as CountDown;

        if(contractInfo.launchTime.getTime()>Date.now()){
            //button.classList.add("disabled");
            button.querySelector(".bi-rocket").remove();
            button.style.filter = "grayscale(1)";
            countDown.date = contractInfo.launchTime;
        }else{
            //button.classList.remove("disabled");
            countDown.classList.add("d-none");
        }
        return button;
    }

    static buildChainList(): {nextLaunchTime: Date, chains: UdiceContractInfoWithButton[]}{
        const futureLaunches = ContractsRegistry.getInstance().list().map(item => item.launchTime)
            .filter(a => a.getTime() > Date.now() - (1000 * 60 * 60 * 24 * 7));
        let  nextLaunchTime: Date;
        if(futureLaunches.length>0){
            nextLaunchTime = futureLaunches.reduce((a,b)=>a.getTime()<=b.getTime()?a:b);
        }else{
            nextLaunchTime = null;
        }
        /*mainCountDown.date = nextLaunchTime;
        if(nextLaunchTime!=null && nextLaunchTime.getTime() < Date.now()){
            mainCountDown.remove();
        }*/

        let chainsToShow = ContractsRegistry.getInstance().list().filter(chain=>{
            if(nextLaunchTime!=null && nextLaunchTime.getTime() < chain.launchTime.getTime()){
                //only show those that have launched, or launch next
                return false;
            }

            if(nextLaunchTime==null || chain.launchTime.getTime() <= nextLaunchTime.getTime()){
                return true;
            }else{
                return false;
            }
            
        }).map(chainInfo=>{
            let button = UDICELanding.createButton(chainInfo);
            return {contractInfo: chainInfo, button: button};
        });
        return {nextLaunchTime: nextLaunchTime, chains:chainsToShow}
    }

}
customElements.define("udice-application-landing", UDICELanding);

class UDICELandingLight extends HTMLElement{

    constructor(){
        super();
        this.innerHTML = `
            <h2 class="mt-5 border-bottom">Choose your favorite community:</h2>
            <div class="list-group m-3 mt-1"></div>
        `;

        let listGroup = this.querySelector(".list-group");
        
        let chains = UDICELanding.buildChainList();

        chains.chains.forEach(chainInfo=>{
            listGroup.appendChild(chainInfo['button']) 
        });
    }
}
customElements.define("udice-landing-light", UDICELandingLight);

export function showStartDialog(){
    let light = new UDICELandingLight();

    UDICEDialogOptions.show({bodyHtml: light});
}
class UDICELandingStartButton extends HTMLElement{
    static dummy(){}
    constructor(){
        super();
        
        this.addEventListener("click", e=>{
            let chains = UDICELanding.buildChainList();

            if(chains.chains.length==1){
                window.location.href = UDICELanding.chainUrl(chains.chains[0].contractInfo);
                return;
            }
            let light = new UDICELandingLight();

            UDICEDialogOptions.show({titleHtml: "Start", bodyHtml: light, fullscreen: true, scrollable: true});
        });
    }
}
customElements.define("udice-landing-start-button", UDICELandingStartButton);
/*UDICELandingStartButton.dummy();*/

class UDICELandingCountDownWidget extends HTMLElement{
    constructor(){
        super();
        let dates = ContractsRegistry.getInstance().list().filter(c=>!c.testnet).map(c=>c.launchTime).sort((a,b)=>{
            return b.getTime()-a.getTime();
        });
        if(dates.length>0 && dates[0].getTime()>=Date.now()){
            this.innerHTML = `<udice-countdown class="countdown"></udice-countdown>`;
            let countDown: CountDown = this.querySelector("udice-countdown") as CountDown;
            countDown.date = dates[0];
        }        
    }
}
customElements.define("udice-landing-count-down", UDICELandingCountDownWidget);