
export class ReferralManager{

    private static readonly referrerUrlParamName = "urue";

    private static readonly referrerLocalStoragePropertName = "udice-application-referrer";

    public static init() : boolean{
        if(this.isReferred()){
            console.log("Referrer already assigned: "+this.getReferrer());
            return true;
        } 
        const queryString = window.location.search;
        if(queryString==null || queryString=="") return;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.get(ReferralManager.referrerUrlParamName)==null || urlParams.get(ReferralManager.referrerUrlParamName)==""){
            return false;
        }
        console.log("Referrer detected");
        const urue = BigInt(urlParams.get(ReferralManager.referrerUrlParamName));
        this.setReferrer(urue);
        console.log("Referrer set to "+urue)
        return true;
    }

    public static isReferred(){
        return this.getReferrer()!=null;
    }
    public static getReferrer():bigint{
        if(window.localStorage.getItem(ReferralManager.referrerLocalStoragePropertName)!=null){
            return BigInt(window.localStorage.getItem(ReferralManager.referrerLocalStoragePropertName));
        }
        return undefined;
    }
    private static setReferrer(r:BigInt){
        window.localStorage.setItem(ReferralManager.referrerLocalStoragePropertName, r.toString(10));
    }

}
ReferralManager.init();