import * as UDICEConfig from './udice.config.js';
import "./udice-app.ts";
import {abi as BNBContractAbi} from "../../../udice-web3/contracts/artifacts/UDICE_BNB.json";
import { createIUdiceContractSupport } from './udice-pancakeswap.ts';

/*
  JSON RPC HTTP(s) Endpoint

(How to use endpoints)

https://endpoints.omniatech.io/v1/bsc/testnet/ceee6b92cad4479fa6baa69c272be4a6
JSON RPC Websocket Endpoint

wss://endpoints.omniatech.io/v1/ws/bsc/testnet/ceee6b92cad4479fa6baa69c272be4a6
*/
let BNB_MAINNET :UDICEConfig.IChainInfo = {
  "name": "BNB Smart Chain Mainnet",
  "chain": "BSC",
  "rpc": [
    "https://bsc-mainnet.chainnodes.org/71a2f3c3-37e3-4146-951d-ae8621d09889",
    "wss://bsc-mainnet.chainnodes.org/71a2f3c3-37e3-4146-951d-ae8621d09889",
    //"https://bsc-rpc.publicnode.com",
    //"https://endpoints.omniatech.io/v1/bsc/mainnet/9fb8fe9158b04ac19c232db012d494ed",
    //"wss://endpoints.omniatech.io/v1/ws/bsc/mainnet/9fb8fe9158b04ac19c232db012d494ed"
    /*
    "https://bsc-dataseed1.bnbchain.org",
    "https://bsc-dataseed2.bnbchain.org",
    "https://bsc-dataseed3.bnbchain.org",
    "https://bsc-dataseed4.bnbchain.org",
    "https://bsc-dataseed1.defibit.io",
    "https://bsc-dataseed2.defibit.io",
    "https://bsc-dataseed3.defibit.io",
    "https://bsc-dataseed4.defibit.io",
    "https://bsc-dataseed1.ninicoin.io",
    "https://bsc-dataseed2.ninicoin.io",
    "https://bsc-dataseed3.ninicoin.io",
    "https://bsc-dataseed4.ninicoin.io",
    "https://bsc-rpc.publicnode.com",
    "wss://bsc-rpc.publicnode.com",
    "wss://bsc-ws-node.nariox.org"
    */
  ],
  "faucets": [],
  "nativeCurrency": {
    "name": "BNB Chain Native Token",
    "symbol": "BNB",
    "decimals": 18
  },
  "infoURL": "https://www.bnbchain.org/en",
  "shortName": "bnb",
  "chainId": 56,
  "networkId": 56,
  "slip44": 714,
  "explorers": [
    {
      "name": "bscscan",
      "url": "https://bscscan.com",
      "standard": "EIP3091"
    },
    {
      "name": "dexguru",
      "url": "https://bnb.dex.guru",
      "icon": "dexguru",
      "standard": "EIP3091"
    }
  ]  
};
UDICEConfig.registerChain({
    ...BNB_MAINNET
  });

  UDICEConfig.ContractsRegistry.getInstance().register({
    "displayName": "Binance Smart Chain (BNB)",
    "contractAddress": "0x8019f0E145566f16D9D5c4E02D0837851D7d15a9",
    "playGas": "400000",
    "contractAbi": BNBContractAbi,
    "iconUrl": UDICEConfig.bnb_icon,
    "defaultWager": "0010000000000000000",
    "created": new Date(Date.UTC(2024, 7-1, 3, 0, 32, 0, 0)),
    "launchTime": new Date(Date.UTC(2024, 7, 15, 0, 0, 0, 0)),
    "chain": BNB_MAINNET
  });

  UDICEConfig.ContractsRegistry.getInstance().register({
    "displayName": "Binance Smart Chain (BNB)",
    "contractAddress": "0x657BfE17b3C44Fcbc738Dd0be74a619c4470072A",
    "playGas": "400000",
    "contractAbi": BNBContractAbi,
    "iconUrl": UDICEConfig.bnb_icon,
    "defaultWager": "0010000000000000000",
    "created": new Date(Date.UTC(2024, 7-1, 3, 10, 4, 0, 0)),
    "launchTime": new Date(Date.UTC(2024, 8-1, 15, 0, 0, 0, 0)),
    "chain": BNB_MAINNET
  });

  UDICEConfig.ContractsRegistry.getInstance().register({
    "displayName": "Binance Smart Chain (BNB)",
    "contractAddress": "0x9032FDf3D4440f57C751A4feA4A6bC732CBCA33A",
    "playGas": "400000",
    "contractAbi": BNBContractAbi,
    "iconUrl": UDICEConfig.bnb_icon,
    "defaultWager": "0010000000000000000",
    "created": new Date(Date.UTC(2024, 8-1, 7, 21, 4, 27)),
    "launchTime": UDICEConfig.default_launch_time,
    "chain": BNB_MAINNET,
    "support": (web3)=>createIUdiceContractSupport(web3)
  });